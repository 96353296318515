import { ComputedFilter } from "../metadata/metadata.module";
import { Action, Pricing } from "../pricing_list/pricingListSlice";

export interface GroupedField {
	field_key: string;
	field_value: string;
	children?: GroupedField[] | null;
	count: number;
	filters: ComputedFilter[];
	actions: Action[];
	// next are frontend only fields
	isNewUpdate?: boolean;
	isExpanded?: boolean;
	isSelected?: boolean;
	pricings?: Pricing[];
	pricingsAreLoading?: boolean;
	thereAreMorePricingsToLoad?: boolean;
	page?: number;
	isStatusUpdating?: boolean;
	group_label?: string;
}

export type GroupedResponseCount = GroupedField[];

export interface PricingGroupId {
	field_key: string;
	field_value: string;
}

export function groupId(group: GroupedField) {
	return group.field_key + group.field_value;
}

export function flattenGroupChildren(group: GroupedField): GroupedField[] {
	return (group.children || []).reduce<GroupedField[]>(
		(prev, current) =>
			prev.concat([current]).concat(flattenGroupChildren(current)),
		[]
	);
}
