import { Action, Pricing } from "../../pricingListSlice";
import { uniq } from "lodash";

export const filterActions = (
	pricings: Pricing[],
	actions: Action[]
): Action[] => {
	if (!pricings.length) {
		return actions;
	}
	const res: Action[] = actions.filter((action) => {
		if (action.by_site_or_portfolios) {
			const sites = pricings.map((p) =>
				p.site_pricings
					.map((site_pricing) => site_pricing.site.id)
					.sort()
					.join(",")
			);
			const equalBySites = sites.every((val) => val === sites[0]);

			const portfolios = pricings.map((p) => p.portfolio_id);
			const equalByPortfolios = portfolios.every(
				(val) => val && val === portfolios[0]
			);
			return equalBySites || equalByPortfolios;
		}
		if (action.is_unique_by_group) {
			const groupIds = pricings.map((p) =>
				JSON.stringify(p.pricing_group_id)
			);
			const uniqueByGroupIds = uniq(groupIds);
			return uniqueByGroupIds.length === groupIds.length;
		}
		if (action.is_unique_by_pricing_type) {
			const pricingTypeIds = pricings.map((p) =>
				JSON.stringify(p.pricing_type_id)
			);
			return uniq(pricingTypeIds).length === 1;
		}
		if (pricings.length > 1 && action.action === "ADD_PARTY") {
			return false;
		}

		return true;
	});
	return res;
};
