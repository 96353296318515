import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../../common/utils/customBaseQuery";
import {
	ContractDetailType,
	ContractPeriodClickObjectsResponseType,
	GetContractParamsType,
	GetContractPeriodClickObjectsParamsType,
	GetContractsResponseType,
	ContractPeriodClickObjectType,
	GetContractPeriodServicePointsType,
	ContractPeriodServicePointsResponseType,
	UnlinkContractPeriodServicePoints,
	UpsertContractPeriodServicePoints,
	HorizonChangeContractPeriodResponseType,
	GetHorizonChangeParams,
	ContractPeriodDealsResponseType,
	BookingLogsListType,
	GetBookingLogsParamsType,
	ContractPeriodIdParamsType,
	ContractPeriodCurtailmentObjectsResponseType,
	ContractPeriodCurtailmentObjectsAuditsParamsType,
	ContractPeriodCurtailmentObjectsAuditsResponseType,
	ContractAttachementsResponseType,
	ContractAttachementsParamsType,
	ContractAttachementUploadParamType,
	ContractAttachementDeleteParamType,
	ContractAttachementUploadResponseType,
	ContractStatus,
} from "./contractsApi.types";
import { merge, serializeQueryArgs } from "../infiniteScroll.helpers";
import { DynamicEndpointQuery, PageInfoType } from "../gecoTypes";

export const contractsSearchableColumns = ["name", "uid"];
export const contractsSortableColumns = [
	"id",
	"name",
	"uid",
	"start_date",
	"end_date",
];
export const clickabilitySortableColumns = [
	"click_date",
	"click_start",
	"click_end",
	"price",
	"mid_price",
	"volume_percentage",
	"ot_sync",
	"type_id",
	"last_sync_update",
];

const contractsApi = createApi({
	reducerPath: "contractsApi",
	baseQuery: customBaseQuery({
		baseURL: process.env.REACT_APP_GECO_API_PATH!,
	}),
	tagTypes: [
		"Clicks",
		"Contract",
		"ContractPeriodBookingLogs",
		"Deals",
		"HorizonChange",
		"ServicePoints",
		"ContractAttachements",
	],
	endpoints: (builder) => ({
		getContracts: builder.query<
			GetContractsResponseType,
			PageInfoType & DynamicEndpointQuery
		>({
			query: ({ page, filters, search, sortOn, order, endpoint }) => {
				const searchParams = search
					? contractsSearchableColumns.reduce(
							(acc, curr) => ({
								...acc,
								[`search__${curr}`]: search,
							}),
							{}
					  )
					: {};

				return {
					url: `${endpoint || ""}/contracts`,
					method: "GET",
					params: {
						page,
						per_page: 25,
						...searchParams,
						...filters,
						sort:
							sortOn && order
								? `${order === "DESC" ? "-" : ""}${sortOn}`
								: "-id",
					},
				};
			},
			serializeQueryArgs: serializeQueryArgs,
			merge: merge,
		}),
		getOneContract: builder.query<
			ContractDetailType,
			GetContractParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_id, endpoint, skip_validation = true }) => {
				return {
					url: `${endpoint || ""}/contracts/${contract_id}`,
					method: "GET",
					params: {
						skip_validation,
					},
				};
			},
			providesTags: ["Contract"],
		}),
		updateOneContract: builder.mutation<
			void,
			Pick<ContractDetailType, "id"> &
				Partial<ContractDetailType> &
				DynamicEndpointQuery
		>({
			query: ({ endpoint, ...contrat }) => {
				return {
					url: `${endpoint || ""}/contracts/${contrat.id}`,
					method: "PUT",
					data: contrat,
				};
			},
			invalidatesTags: ["Contract"],
		}),
		updateOneContractDryRun: builder.mutation<
			void,
			Pick<ContractDetailType, "id"> &
				Partial<ContractDetailType> &
				DynamicEndpointQuery
		>({
			query: ({ endpoint, ...contrat }) => {
				return {
					url: `${endpoint || ""}/contracts/${contrat.id}/dry_run`,
					method: "PUT",
					data: contrat,
				};
			},
			invalidatesTags: ["Contract"],
		}),
		updateContractStatus: builder.mutation<
			void,
			{
				contract_id: number;
				status: ContractStatus;
			} & DynamicEndpointQuery
		>({
			query: ({ endpoint, contract_id, status }) => {
				return {
					url: `${endpoint || ""}/contracts/${contract_id}/status`,
					method: "PATCH",
					data: { status },
				};
			},
			invalidatesTags: ["Contract"],
		}),

		getContractPeriodClickObjects: builder.query<
			ContractPeriodClickObjectsResponseType,
			GetContractPeriodClickObjectsParamsType & DynamicEndpointQuery
		>({
			query: ({
				contract_period_id,
				sortOn,
				order,
				endpoint,
				...filters
			}) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/click_objects`,
					method: "GET",
					params: {
						...filters,
						sort:
							sortOn && order
								? `${order === "DESC" ? "-" : ""}${sortOn}`
								: undefined,
					},
				};
			},
			providesTags: ["Clicks"],
		}),
		getContractPeriodHorizonChange: builder.query<
			HorizonChangeContractPeriodResponseType,
			GetHorizonChangeParams & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint, ...filters }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/change_horizon`,
					method: "GET",
					params: {
						...filters,
					},
				};
			},
			providesTags: ["HorizonChange"],
		}),
		updateContractPeriodHorizonChange: builder.mutation<
			null,
			HorizonChangeContractPeriodResponseType &
				DynamicEndpointQuery & { contractPeriod_id: number }
		>({
			query: ({ contractPeriod_id, endpoint, proposed_changes }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contractPeriod_id}/change_horizon`,
					method: "PUT",
					data: {
						proposed_changes,
					},
				};
			},
			invalidatesTags: ["Contract"],
		}),
		createContractPeriodClickObject: builder.mutation<
			void,
			Partial<ContractPeriodClickObjectType> & {
				contract_period_id: number;
			} & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint, ...click }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/click_objects`,
					method: "POST",
					data: click,
				};
			},
			invalidatesTags: ["Clicks"],
		}),

		getContractPeriodServicePoints: builder.query<
			ContractPeriodServicePointsResponseType,
			GetContractPeriodServicePointsType & DynamicEndpointQuery
		>({
			query: ({ contractPeriod_id, page, per_page, sort, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contractPeriod_id}/servicepoints`,
					method: "GET",
					params: {
						page,
						per_page,
						sort,
					},
				};
			},
			providesTags: ["ServicePoints"],
		}),

		unlinkContractPeriodServicePoints: builder.mutation<
			null,
			UnlinkContractPeriodServicePoints & DynamicEndpointQuery
		>({
			query: ({ contractPeriod_id, servicepoint_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contractPeriod_id}/servicepoints/${servicepoint_id}`,
					method: "PUT",
					data: {
						contractperiod_servicepoints: [],
					},
				};
			},
			invalidatesTags: ["ServicePoints"],
		}),
		upsertContractPeriodServicePoints: builder.mutation<
			null,
			UpsertContractPeriodServicePoints & DynamicEndpointQuery
		>({
			query: ({
				contractPeriod_id,
				servicepoint_id,
				contractperiod_servicepoints,
				endpoint,
			}) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contractPeriod_id}/servicepoints/${servicepoint_id}`,
					method: "PUT",
					data: {
						contractperiod_servicepoints,
					},
				};
			},
			invalidatesTags: ["ServicePoints"],
		}),

		getDeals: builder.query<
			ContractPeriodDealsResponseType[],
			{ contract_period_id: number } & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/deals`,
					method: "GET",
				};
			},
			providesTags: ["Deals"],
		}),
		cancelDeal: builder.mutation<
			void,
			{
				contract_period_id: number;
				deal_id: number;
				isCompensation: boolean;
			} & DynamicEndpointQuery
		>({
			query: ({
				contract_period_id,
				deal_id,
				isCompensation,
				endpoint,
			}) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/${
						isCompensation ? "compensation_" : ""
					}deals/${deal_id}/cancel`,
					method: "PATCH",
				};
			},
			invalidatesTags: ["Deals"],
		}),
		getBookingLogs: builder.query<
			BookingLogsListType,
			GetBookingLogsParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, logs_from, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/logs`,
					method: "GET",
					params: {
						logs_from,
					},
				};
			},
			providesTags: ["ContractPeriodBookingLogs"],
		}),
		getContractPeriodCurtailmentObjects: builder.query<
			ContractPeriodCurtailmentObjectsResponseType,
			ContractPeriodIdParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/curtailment_objects`,
					method: "GET",
				};
			},
		}),
		getContractPeriodCurtailmentObjectsAudits: builder.query<
			ContractPeriodCurtailmentObjectsAuditsResponseType,
			ContractPeriodCurtailmentObjectsAuditsParamsType &
				DynamicEndpointQuery
		>({
			query: ({ curtailment_object_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/curtailment_objects/${curtailment_object_id}/audit`,
					method: "GET",
				};
			},
		}),

		syncContractPeriodCompensationBCV: builder.mutation<
			void,
			ContractPeriodIdParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/send_compensation_bcv`,
					method: "POST",
				};
			},
		}),
		syncContractPeriodCompensationBookDeals: builder.mutation<
			void,
			ContractPeriodIdParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/send_compensation_bcv`,
					method: "POST",
				};
			},
		}),
		syncContractPeriodBookDeals: builder.mutation<
			void,
			ContractPeriodIdParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/book_deal`,
					method: "POST",
				};
			},
		}),
		syncContractPeriodBCV: builder.mutation<
			void,
			ContractPeriodIdParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_period_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contractperiods/${contract_period_id}/send_bcv`,
					method: "POST",
				};
			},
		}),
		getContractAttachements: builder.query<
			ContractAttachementsResponseType,
			ContractAttachementsParamsType & DynamicEndpointQuery
		>({
			query: ({ contract_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contracts/${contract_id}/attachments`,
					method: "GET",
				};
			},
			providesTags: ["ContractAttachements"],
		}),
		uploadContractAttachement: builder.mutation<
			ContractAttachementUploadResponseType,
			ContractAttachementUploadParamType & DynamicEndpointQuery
		>({
			query: ({ contract_id, filename, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contracts/${contract_id}/attachments`,
					method: "POST",
					data: { filename },
				};
			},
			invalidatesTags: ["ContractAttachements"],
		}),
		deleteContractAttachement: builder.mutation<
			void,
			ContractAttachementDeleteParamType & DynamicEndpointQuery
		>({
			query: ({ contract_id, attachement_id, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/contracts/${contract_id}/attachments/${attachement_id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: ["ContractAttachements"],
		}),
	}),
});

export const {
	useCancelDealMutation,
	useCreateContractPeriodClickObjectMutation,
	useDeleteContractAttachementMutation,
	useGetContractAttachementsQuery,
	useGetContractPeriodCurtailmentObjectsAuditsQuery,
	useGetContractPeriodCurtailmentObjectsQuery,
	useGetOneContractQuery,
	useLazyGetBookingLogsQuery,
	useLazyGetContractPeriodClickObjectsQuery,
	useLazyGetContractPeriodHorizonChangeQuery,
	useLazyGetContractPeriodServicePointsQuery,
	useLazyGetContractsQuery,
	useLazyGetDealsQuery,
	useLazyGetOneContractQuery,
	useUnlinkContractPeriodServicePointsMutation,
	useUpdateContractPeriodHorizonChangeMutation,
	useUpdateContractStatusMutation,
	useUpdateOneContractDryRunMutation,
	useUpdateOneContractMutation,
	useUploadContractAttachementMutation,
	useUpsertContractPeriodServicePointsMutation,
	useSyncContractPeriodBCVMutation,
	useSyncContractPeriodBookDealsMutation,
	useSyncContractPeriodCompensationBCVMutation,
	useSyncContractPeriodCompensationBookDealsMutation,
} = contractsApi;

export default contractsApi;
