import {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import { apiGet } from "../utils/request.utils";

export interface TemporaryFetch<T> {
	clearTemporaryData: () => void;
	fetchTemporaryData: (query: string) => Promise<void>;
	data: T;
	total: number;
	isLoading: boolean;
	setData: Dispatch<SetStateAction<T | undefined>>;
}

export function useTemporaryFetch<T>(
	dataAccessor?: (response: any) => T,
	totalAccessor?: (response: any) => number | undefined
): TemporaryFetch<T> {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<T>();
	const [total, setTotal] = useState<number>(0);

	const fetchTemporaryData = useCallback(
		async (query: string) => {
			try {
				setIsLoading(true);
				const response = await apiGet(query);
				if (dataAccessor) {
					setData(dataAccessor(response));
				} else {
					setData(response?.data?.data || []);
				}
				if (totalAccessor) {
					setTotal(totalAccessor(response) || 0);
				} else {
					setTotal(response.data?.total || 0);
				}
			} finally {
				setIsLoading(false);
			}
		},
		[setIsLoading, setData, setTotal, totalAccessor, dataAccessor]
	);

	const clearTemporaryData = useCallback(() => {
		setData(undefined);
		setIsLoading(false);
	}, [setData, isLoading]);

	return useMemo(
		() => ({
			data: data as T,
			isLoading,
			total,
			setData,
			clearTemporaryData: clearTemporaryData,
			fetchTemporaryData: fetchTemporaryData,
		}),
		[
			data,
			isLoading,
			total,
			setData,
			clearTemporaryData,
			fetchTemporaryData,
		]
	);
}
