import _ from "lodash";
import {
	ContractDetailType,
	ContractParty,
} from "../../../requests_geco/contractsApi/contractsApi.types";

export interface CounterpartyFormik {
	party: ContractParty | undefined;
	mirror_book: string | undefined;
	way: "Buy" | "Sell" | undefined;
}

export const counterpartyTabFormikInitialValueMapper = (
	data: ContractDetailType | undefined
): CounterpartyFormik => {
	return {
		party: data?.party,
		mirror_book: data?.mirror_book,
		way: data?.way,
	};
};

export const counterpartyFormikToContractDetailMapper = (
	formikData: CounterpartyFormik,
	initialContract: ContractDetailType
): ContractDetailType => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	updatedContract.party = formikData.party as ContractParty;
	updatedContract.way = formikData.way;
	if (formikData?.mirror_book) {
		updatedContract.mirror_book = formikData.mirror_book as string;
		updatedContract.mirror_book_owner = formikData.party
			?.mnemonic as string;
	}

	return updatedContract;
};
