import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, SxProps, Theme } from "@mui/material";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../common/components/CustomButton";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { useAppDispatch } from "../../common/hooks/default";
import { grey, lightGrey, pagePadding, white } from "../../core/theme";
import { Tender } from "../tender_page/tender.module";
import { TenderSection } from "./components/TenderSection/TenderSection";
import { TenderActions } from "./components/TenderSection/TenderActions";
import {
	selectCanPrintUnderOffer,
	selectPricingsTender,
} from "./pricingList.selector";
import {
	selectPersistedFilters,
	selectPricingsOdataQuery,
} from "../filters/filters.selector";
import FiltersDrawer from "../filters/Filters";
import { selectPricingsGroupingMetadata } from "../metadata/metadata.selector";
import {
	selectAllPricings,
	selectPricingSelectedGroupingFields,
} from "../grouping/grouping.selector";
import { GroupsSelector } from "../../common/components/GroupsSelector/GroupsSelector";
import * as groupingActions from "../grouping/grouping.slice";
import { ValueOrPercentContext } from "./ValueOrPercentContext";
import { usePricingListColumnSelection } from "./hooks/columns/usePricingListColumnSelection";
import { useAvailablePricingListColumns } from "./hooks/columns/pricingListColumns";
import * as _ from "lodash";
import { PATH } from "../../router-path";
import { PricingGroupTable } from "../pricing_groups/PricingGroupTable";
import { usePricingActionsV2 } from "./hooks/actions/usePricingActionsV2";
import { getTender } from "./pricingList.thunk";
import { getMetadata } from "../metadata/metadata.thunk";
import wsPublisher$ from "../../core/wsPublisher";
import { WsEventTypes } from "../../common/constants/wsEvents";
import { WsMessage } from "../../core/socketProvider";
import { Pricing } from "./pricingListSlice";

export const style: { [key: string]: SxProps<Theme> } = {
	container: {
		height: "100%",
		minWidth: "1888px",
		bgcolor: lightGrey,
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		overflow: "hidden",
		marginBottom: "10px",
		padding: `0 ${pagePadding}`,
	},
	titleContainer: {
		display: "flex",
		height: 80,
		width: "100%",
		alignItems: "center",
	},
	title: {
		marginBottom: "30px",
		flexGrow: "2",
		fontWeight: 400,
		fontSize: "36px",
		margin: 0,
		display: "flex",
		alignItems: "center",
	},
	tableWrapper: {
		flex: "1",
		marginTop: "30px",
		border: `1px solid ${grey}`,
		width: "calc(100vw - 80px)",
	},
	siteOrPortfolioContainer: {
		display: "flex",
		gap: 1,
	},
	stickyRow: {
		position: "sticky",
		left: 0,
		paddingLeft: "5px !important",
		paddingTop: "10px !important",
		paddingBottom: "10px !important",
		background: white,
		"& > td": { padding: "0px" },
		width: "50px !important",
	},
	wayPoint: {
		height: 10,
	},
};

interface PricingListProps {
	maximized?: boolean;
}

const PricingListPage: React.FC<PricingListProps> = (
	props: PricingListProps
) => {
	const navigate = useNavigate();
	const dispatch: Dispatch<any> = useAppDispatch();
	const { tenderId } = useParams();
	const [valueOrPercent, setValueOrPercent] = useState<"value" | "percent">(
		"percent"
	);
	const tender: Tender | undefined = useSelector(
		selectPricingsTender,
		_.isEqual
	);
	useEffect(() => {
		(async function () {
			if (tenderId) {
				await dispatch(getTender(parseInt(tenderId)));
				dispatch(getMetadata("Pricings", parseInt(tenderId)));
			}
		})();
	}, [tenderId, dispatch]);
	const canPrintUnderPricing = useSelector(selectCanPrintUnderOffer);
	const availableGroups = useSelector(
		selectPricingsGroupingMetadata,
		_.isEqual
	);
	const selectedGroups = useSelector(selectPricingSelectedGroupingFields);
	const persistedFilters = useSelector(selectPersistedFilters, _.isEqual);
	const filterCount = useMemo(() => {
		return persistedFilters.filters?.pricings_filters_count ?? 0;
	}, [persistedFilters.filters?.pricings_filters_count]);

	const odataQuery = useSelector(selectPricingsOdataQuery);
	const allPricings = useSelector(selectAllPricings);

	const {
		actionModal,
		availableActions,
		actOnPricings,
		onDownloadRunToExcelByRunIds,
		onDownloadSelectedRunsToExcel,
		onPricingsDownload,
		clearSelection,
		selectAll,
		onDownloadPbParams,
		onDownloadJson,
		actOnTender,
	} = usePricingActionsV2(parseInt(tenderId || "0") || 0);

	const availableColumns = useAvailablePricingListColumns(allPricings);
	const { selectedColumns, onColumnToggled } =
		usePricingListColumnSelection();

	const selectedAndAvailableColumns = useMemo(
		() =>
			selectedColumns
				.filter(
					(column) =>
						!!_.find(
							availableColumns,
							(available) => available.id === column.id
						)
				)
				.filter(
					(column) =>
						!_.find(
							availableColumns,
							(available) => available.id === column.id
						)?.disabled
				),
		[availableColumns, selectedColumns]
	);

	// reload tender on tender updated
	useEffect(() => {
		const reloadTender = (messages: WsMessage<Tender>[]) => {
			if (tenderId) {
				if (
					messages.filter(
						(message) => message.data.id === parseInt(tenderId)
					).length
				) {
					dispatch(getTender(parseInt(tenderId)));
				}
			}
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_UPDATED],
			reloadTender
		);
	}, [dispatch]);

	// reload tender on pricing updated (batch actions)
	useEffect(() => {
		const reloadTender = (messages: WsMessage<Pricing>[]) => {
			if (tenderId) {
				if (
					messages.filter(
						(message) =>
							message.data.tender_id === parseInt(tenderId)
					).length
				) {
					dispatch(getTender(parseInt(tenderId)));
				}
			}
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.PRICING_UPDATED, WsEventTypes.PRICING_CREATED],
			reloadTender
		);
	}, [dispatch]);

	// reload tender on pickup and cancel
	useEffect(() => {
		const reloadTender = (messages: WsMessage<any>[]) => {
			if (tenderId) {
				if (
					messages.filter(
						(message) => message.tender_id === parseInt(tenderId)
					).length
				) {
					dispatch(getTender(parseInt(tenderId)));
				}
			}
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_PICKUP, WsEventTypes.PRICINGS_CANCELLED],
			reloadTender
		);
	}, [dispatch]);

	return (
		<>
			{!props.maximized && (
				<PageTitle
					label="My Pricings"
					leftSide={
						<Box display="flex" justifyContent="flex-start">
							<PrimaryButton
								text="My Tenders"
								type="submit"
								color="info"
								sx={{
									width: 140,
									color: "#171D21",
								}}
								onClick={() => navigate(PATH.TENDERS)}
							>
								<ChevronLeftIcon />
							</PrimaryButton>
						</Box>
					}
				/>
			)}
			<Box
				sx={style.container}
				style={
					props.maximized ? { marginTop: "-126px", zIndex: 0 } : {}
				}
				id="page-list-container"
			>
				{!props.maximized && <TenderSection tender={tender} />}
				<FiltersDrawer page="Pricings" id={tender?.id} />

				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<GroupsSelector
						onAddgroup={(group) =>
							dispatch(groupingActions.addGroup(group))
						}
						onRemoveGroup={(group) =>
							dispatch(groupingActions.removeGroup(group))
						}
						setGroups={(groups) =>
							dispatch(groupingActions.setGroups({ groups }))
						}
						onReset={() => dispatch(groupingActions.clearGroups())}
						availableGroups={availableGroups}
						selectedGroups={selectedGroups}
					/>
					<TenderActions
						availableColumns={availableColumns}
						pricings={allPricings}
						valueOrPercent={valueOrPercent}
						canPrintUnderPricing={canPrintUnderPricing}
						tender={tender}
						setValueOrPercent={setValueOrPercent}
						filterCount={Number(filterCount)}
						selectedColumns={selectedColumns}
						onColumnToggled={onColumnToggled}
						onPricingsDownload={onPricingsDownload}
						actOnPricings={actOnTender}
					/>
				</Box>
				<ValueOrPercentContext.Provider value={valueOrPercent}>
					<PricingGroupTable
						selectedColumns={selectedAndAvailableColumns}
						tenderId={parseInt(tenderId || "0")}
						odataQuery={odataQuery}
						clearSelection={clearSelection}
						availableActions={availableActions}
						selectAll={selectAll}
						actOnPricings={actOnPricings}
						onDownloadSelectedRunsToExcel={
							onDownloadSelectedRunsToExcel
						}
						onPricingsDownload={onPricingsDownload}
						onDownloadRunToExcelByRunIds={
							onDownloadRunToExcelByRunIds
						}
						onDownloadPbParams={onDownloadPbParams}
						onDownloadJson={onDownloadJson}
						actionModal={actionModal}
					/>
				</ValueOrPercentContext.Provider>
			</Box>
		</>
	);
};

export default PricingListPage;
