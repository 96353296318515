import _ from "lodash";
import { Formik, type FormikProps } from "formik";
import Counterparty from "./CounterPartyFields";
import {
	ContractDetailType,
	ErrorType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import {
	CounterpartyFormik,
	counterpartyTabFormikInitialValueMapper,
} from "../../contract_validation/formik/counterpartyFormik";
import {
	useLazyGetPartyDetailQuery,
	useLazySearchPartiesQuery,
} from "../../../requests_geco/gepoTprProxyApi";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useGetBooksQuery } from "../../../requests_geco/referentialApi/referentialApi";
import { useCallback, useMemo } from "react";
import { NJRadioGroup, NJRadio } from "@engie-group/fluid-design-system-react";

export interface CounterpartyFormProps {
	formRef: React.Ref<FormikProps<CounterpartyFormik>>;
	contract: ContractDetailType | undefined;
	onSubmit: (data: CounterpartyFormik) => void;
}

const CounterpartyForm = ({
	formRef,
	contract,
	onSubmit,
}: CounterpartyFormProps) => {
	const [
		getPartyDetail,
		{
			data: detailParty,
			isLoading: isDetailLoading,
			error: detailPartyError,
		},
	] = useLazyGetPartyDetailQuery();
	const [searchParties, { data: parties, isLoading: isSearchLoading }] =
		useLazySearchPartiesQuery();

	const { data: books, isLoading: isBookLoading } =
		useRtkQueryDynamicEndpoint(useGetBooksQuery)({});

	const booksOptions = useMemo(() => {
		const options: { label: string; value: string | undefined }[] = [
			{ label: "No Mirror Book", value: undefined },
		];
		for (const book of books || []) {
			options.push({ label: book.name, value: book.portfolio_prefix });
		}

		return options;
	}, [books]);

	const getCurrentBookOptionByPrefix = useCallback(
		(portfolio_prefix: string | undefined) => {
			return _.find(
				booksOptions,
				(option: { label: string; value: string }) =>
					option.value == portfolio_prefix
			);
		},
		[booksOptions]
	);

	const getCurrentBookOptionByName = useCallback(
		(name: string | undefined) => {
			return _.find(
				booksOptions,
				(option: { label: string; value: string }) =>
					option.label == name
			);
		},
		[booksOptions]
	);

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			initialValues={counterpartyTabFormikInitialValueMapper(contract)}
			onSubmit={onSubmit}
			innerRef={formRef}
		>
			{({ handleChange, setFieldValue, handleSubmit, values }) => (
				<form onSubmit={handleSubmit} id="counterPartyForm">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "24px",
						}}
					>
						<Counterparty
							values={values}
							parties={parties?.partyResults || []}
							detailParty={detailParty}
							errorMessage={detailPartyError as ErrorType}
							isLoading={isDetailLoading || isSearchLoading}
							setFieldValue={setFieldValue}
							handleChange={handleChange}
							searchParties={searchParties}
							getPartyDetail={getPartyDetail}
							label="Counterpart"
						/>
						<Autocomplete
							sx={{ width: "100%" }}
							loading={isBookLoading}
							//@ts-ignore
							options={booksOptions}
							onInputChange={(_event, inputValue) => {
								setFieldValue(
									"mirror_book",
									getCurrentBookOptionByName(inputValue)
										//@ts-ignore
										?.value || ""
								);
							}}
							//@ts-ignore
							defaultValue={getCurrentBookOptionByPrefix(
								values.mirror_book || ""
							)}
							inputValue={values.mirror_book}
							renderInput={(params) => (
								<TextField
									name="mirror_book"
									{...params}
									label="Select mirror book"
								/>
							)}
						/>

						<NJRadioGroup
							checkedId={values.way}
							legend="Contract Way"
							onChange={(way) => {
								setFieldValue("way", way);
							}}
							orientation="row"
						>
							<NJRadio
								id={"Buy"}
								label={"Buy"}
								name="way"
								value={"Buy"}
							/>
							<NJRadio
								id={"Sell"}
								label={"Sell"}
								name="way"
								value={"Sell"}
							/>
						</NJRadioGroup>
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default CounterpartyForm;
