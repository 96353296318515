import { FormLabel, MenuItem, Radio, RadioGroup } from "@mui/material";
import { TypeIdOptionType } from "../../../features/contract_period/ContractPeriodTabs/ClickabilityTab/ClickModal/ClickModal.types";
import { style } from "./RadioButtonsGroup.style";
import { Spacer } from "../Spacer";

const RadioButtonsGroup = ({
	options,
	value,
	name,
	label,
	handleChange,
}: {
	options: TypeIdOptionType;
	value: string | undefined;
	name: string;
	label?: string;
	handleChange: any;
}) => {
	return (
		<>
			{label && (
				<>
					<FormLabel id="demo-radio-buttons-group-label">
						{label}
					</FormLabel>
					<Spacer gap={8} />
				</>
			)}
			<RadioGroup
				value={value}
				name={name}
				onChange={handleChange}
				sx={style.wrapper}
			>
				{options.map((option) => (
					<label key={option.label}>
						<Radio
							id={option.label}
							sx={{ display: "none" }}
							value={option.value}
						/>
						<MenuItem
							selected={value === option.value}
							sx={style.item}
						>
							{option.label}
						</MenuItem>
					</label>
				))}
			</RadioGroup>
		</>
	);
};

export default RadioButtonsGroup;
