import * as yup from "yup";
import { ClickUnit } from "../../../requests_geco/contractsApi/contractsApi.types";

export const clickabilityValidator = yup.object().shape({
	click_period: yup
		.string()
		.required("click_period is a required field")
		.nonNullable(),
	click_type: yup
		.string()
		.required("click_period is a required field")
		.nonNullable(),
	right_to_seller: yup
		.string()
		.required("click_period is a required field")
		.nonNullable(),
	max_capacity: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.max(1, "Must be less than or equal to 1")
		.required("Required"),
	min_capacity: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.max(1, "Must be less than or equal to 1"),
	max_click_percentage: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.max(1, "Must be less than or equal to 1")
		.required("Required"),
	min_click_percentage: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.required("Required")
		.when("max_click_percentage", (max_click_percentage, schema) => {
			return schema.max(
				Math.min(max_click_percentage[0], 1),
				"Must be less or equal than max_click_percentage"
			);
		}),
	allow_full_declick: yup.bool().required("Required"),
	max_nb_click: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.required("Required"),
	max_nb_declick: yup
		.number()
		.when("allow_full_declick", (allow_full_declick, schema) => {
			return allow_full_declick[0]
				? schema.min(0).required("Required")
				: schema.nullable();
		}),
	alpha: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.required("Required"),

	beta: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.required("Required"),
	aggregation_fee: yup.number(),
	service_fee: yup.number(),
	last_click_lag: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.required("Required"),
	click_lag_type: yup
		.string()
		.required("click click lag type is a required field"),
	lag_business_day_convention: yup
		.string()
		.required("lag business day convention is a required field"),
	lag_calendar: yup.string().required("lag calendar is a required field"),
});

export const clickValidator = yup.object().shape({
	click_date: yup
		.date()
		.typeError("please enter a valid date")
		.required("click date is a required field"),
	click_end: yup
		.date()
		.typeError("please enter a valid date")
		.required("end date is a required field"),
	click_start: yup
		.date()
		.typeError("please enter a valid date")
		.required("start date is a required field")
		.when("click_end", (click_end, schema) => {
			if (click_end && click_end[0]) {
				return schema.max(
					click_end[0],
					"cannot be greater than end date"
				);
			}
			return schema;
		}),
	click_unit: yup.string().required("Required"),
	mid_price: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.required("Required"),
	price: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0")
		.required("Required"),
	type_id: yup.string().required("Type is a required field").nonNullable(),
	volume: yup.number().when("click_unit", {
		is: (click_unit: any) => click_unit === ClickUnit.MWH,
		then: (schema) => schema.required("Volume is required"),
		otherwise: (schema) => schema.nullable(),
	}),
	volumePct: yup
		.number()
		.min(1, "Must be greater than or equal to 1%")
		.max(100, "Cannot be greater than 100%")
		.when("click_unit", {
			is: (click_unit: any) => click_unit === ClickUnit.PERCENTAGE,
			then: (schema) => schema.required("Volume is required"),
			otherwise: (schema) => schema.nullable(),
		}),
});
