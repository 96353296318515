import React, { useCallback } from "react";
import {
	Pricing,
	PricingRun,
	PricingRunSummaryMetric,
} from "../../pricing_list/pricingListSlice";
import { TableCell } from "@mui/material";
import { Column } from "../../../common/components/ColumnsSelector/ColumnsSelector";
import { If } from "../../../common/components/If";
import { StatelessFlashUpdateTableCell } from "../../../common/components/StatelessFlashUpdateTableCell";
import { PricingRunHamburger } from "../../pricing_list/components/PricingTable/PricingRunHamburger";
import { ColumnIds } from "../../pricing_list/hooks/columns/common";
import { usePricingTableRowModel } from "../../pricing_list/hooks/columns/usePricingTableRowModel";
import { formatPercentage } from "../../../common/utils/formatPercentage";
import { useSelector } from "react-redux";
import { selectExportLoader } from "../../pricing_list/pricingList.selector";

export interface PricingRunLineProps {
	run: PricingRun;
	pricing: Pricing;
	statusIsLoading?: boolean;
	valueOrPercent: "percent" | "value";
	onDownloadRunToExcel: (ids: number[]) => void;
	onDownloadJson: (id: number) => void;
	isScrolling?: boolean;
	openGraphModal: (pricing: Pricing | undefined) => void;
	selectedColumns?: Column<ColumnIds>[];
	openWarningModal: (message: string) => void;
}

const style = {
	cell: {
		height: "34px",
		maxHeight: "34px",
		padding: 0,
		border: 0,
	},
	stickyRow: {
		zIndex: 1,
		position: "sticky",
		left: 0,
		padding: 0,
		backgroundColor: "#F3F5F6",
		border: 0,
	},
};

export function PricingRunLine(props: PricingRunLineProps) {
	const {
		run,
		pricing,
		isScrolling,
		statusIsLoading,
		valueOrPercent,
		onDownloadRunToExcel: onDownloadRunToExcel,
		onDownloadJson: onDownloadJson,
		openGraphModal,
		selectedColumns,
		openWarningModal,
	} = props;

	const rowModel = usePricingTableRowModel(selectedColumns);
	const getValueOrPercent = useCallback(
		(
			metric: PricingRunSummaryMetric | undefined,
			valueFormatter: (value: number | null | undefined) => string
		) => {
			if (!metric) {
				return "-";
			}
			if (
				valueOrPercent === "percent" &&
				typeof metric.percent === "number"
			) {
				if (!metric.percent) {
					return "-";
				}

				return formatPercentage(metric.percent);
			}
			return valueFormatter(metric.value);
		},
		[valueOrPercent]
	);
	const isExportLoading = useSelector(selectExportLoader);

	return (
		<>
			<TableCell sx={{ ...style.stickyRow, ...style.cell }}>
				<TableCell
					sx={{ width: 75, maxWidth: 75, ...style.cell }}
					align="left"
				></TableCell>
				{rowModel.stickyColumns.map((column) => (
					<StatelessFlashUpdateTableCell
						key={column.columnId}
						id={column.cellIdFactory(pricing, run)}
						field={column.fieldValueExtractor({
							openGraphModal,
							pricing,
							valueOrPercent,
							pricingRun: run,
							statusIsLoading,
							isScrolling,
							getValueOrPercent,
							openWarningModal,
						})}
						isUpdate={pricing?.isNewUpdate}
						align="left"
						sx={{
							maxWidth: column.width,
							minWidth: column.width,
							...style.cell,
						}}
					>
						<column.renderCell
							openGraphModal={openGraphModal}
							pricing={pricing}
							valueOrPercent={valueOrPercent}
							pricingRun={run}
							statusIsLoading={statusIsLoading}
							isScrolling={isScrolling}
							getValueOrPercent={getValueOrPercent}
							openWarningModal={openWarningModal}
						/>
					</StatelessFlashUpdateTableCell>
				))}
			</TableCell>
			<If condition={!isScrolling}>
				{rowModel.nonStickyColumns.map((column) => (
					<StatelessFlashUpdateTableCell
						key={column.columnId}
						id={column.cellIdFactory(pricing, run)}
						field={column.fieldValueExtractor({
							openGraphModal,
							pricing,
							valueOrPercent,
							pricingRun: run,
							statusIsLoading,
							isScrolling,
							getValueOrPercent,
							openWarningModal,
						})}
						isUpdate={pricing?.isNewUpdate}
						align="left"
						sx={{
							maxWidth: column.width,
							minWidth: column.width,
							...style.cell,
						}}
					>
						<column.renderCell
							openGraphModal={openGraphModal}
							pricing={pricing}
							valueOrPercent={valueOrPercent}
							pricingRun={run}
							statusIsLoading={statusIsLoading}
							isScrolling={isScrolling}
							getValueOrPercent={getValueOrPercent}
							openWarningModal={openWarningModal}
						/>
					</StatelessFlashUpdateTableCell>
				))}
				<TableCell
					sx={{ width: 40, maxWidth: 40, ...style.cell }}
					align="left"
				></TableCell>
				<TableCell
					sx={{ width: 40, maxWidth: 40, ...style.cell }}
					align="left"
				>
					<PricingRunHamburger
						pricingRun={run}
						isExportLoading={isExportLoading}
						onExportRunToExcel={() =>
							onDownloadRunToExcel([run.id])
						}
						onExportRunToJson={() => onDownloadJson(run.id)}
					/>
				</TableCell>
			</If>
		</>
	);
}
