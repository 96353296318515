import { useEffect } from "react";
import { WsEventTypes } from "../../../common/constants/wsEvents";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/default";
import { WsMessage } from "../../../core/socketProvider";
import wsPublisher$ from "../../../core/wsPublisher";
import { selectPricingSelectedGroupingFields } from "../../grouping/grouping.selector";
import { loadPricingsGroupCount } from "../../grouping/grouping.thunk";
import { Pricing } from "../../pricing_list/pricingListSlice";

export function useReloadGroupCountOnPricingWs(
	tenderId?: number,
	odataQuery?: string
) {
	const dispatch = useAppDispatch();
	const selectedGroups = useAppSelector(selectPricingSelectedGroupingFields);

	useEffect(() => {
		const onPricingChange = (messages: WsMessage<Pricing>[]) => {
			if (tenderId) {
				if (
					messages.filter(
						(message) => message.data.tender_id === tenderId
					).length
				) {
					dispatch(
						loadPricingsGroupCount(
							tenderId,
							odataQuery,
							true,
							selectedGroups
						)
					);
				}
			}
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.PRICING_CREATED, WsEventTypes.PRICING_UPDATED],
			onPricingChange
		);
	}, [dispatch, selectedGroups, tenderId, odataQuery]);
}
