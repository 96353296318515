import { useEffect } from "react";
import { useAppDispatch } from "../../../common/hooks/default";
import { reloadPricingsGroupCount } from "../../grouping/grouping.thunk";
import { WsMessage } from "../../../core/socketProvider";
import wsPublisher$ from "../../../core/wsPublisher";
import { WsEventTypes } from "../../../common/constants/wsEvents";
import { Tender } from "../../tender_page/tender.module";

export function useForceReloadOnTenderChanges(
	tenderId?: number,
	odataQuery?: string
) {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const onPickup = (messages: WsMessage<Tender>[]) => {
			if (tenderId) {
				if (
					messages.filter((message) => message.data.id === tenderId)
						.length
				) {
					dispatch(reloadPricingsGroupCount(tenderId, odataQuery));
				}
			}
		};

		return wsPublisher$.subscribe([WsEventTypes.TENDER_PICKUP], onPickup);
	}, [dispatch, tenderId]);

	useEffect(() => {
		const onCancel = (messages: WsMessage<any>[]) => {
			if (tenderId) {
				if (
					messages.filter(
						(message) => message?.tender_id === tenderId
					).length
				) {
					dispatch(reloadPricingsGroupCount(tenderId, odataQuery));
				}
			}
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.PRICINGS_CANCELLED],
			onCancel
		);
	}, [dispatch, tenderId]);
}
