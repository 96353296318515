type FilterFn<T> = (node: T, depth: number, parents: T[]) => boolean;
type ChildrenAccessor<T> = (node: T) => T[] | null | undefined;

export function visitDepthFirst<T>(
	node: T,
	callbackFn: (node: T, depth: number, parents: T[]) => void,
	childrenAccessor: ChildrenAccessor<T>,
	filterFn: FilterFn<T> = () => true
) {
	const stack: { node: T; depth: number; parents: T[] }[] = [
		{ node: node, depth: 0, parents: [] },
	];

	while (stack.length) {
		const { node: current, depth, parents } = stack.pop()!;
		if (current) {
			const children = childrenAccessor(current);
			if (children && filterFn(current, depth, parents)) {
				stack.push(
					...children.map((newNode) => ({
						node: newNode,
						depth: depth + 1,
						parents: [...parents, current],
					}))
				);
			}
			callbackFn(current, depth, parents);
		}
	}
}

export function maxDepth<T>(
	node: T,
	childrenAccessor: ChildrenAccessor<T>,
	filterFn: FilterFn<T> = () => true
): number {
	let acc = { maxDepth: 0 };

	visitDepthFirst(
		node,
		(_node, depth) => (acc.maxDepth = Math.max(acc.maxDepth, depth)),
		childrenAccessor,
		filterFn
	);

	return acc.maxDepth;
}

export function flattenDepthFirst<T>(
	node: T,
	childrenAccessor: ChildrenAccessor<T>,
	filterFn: FilterFn<T> = () => true
) {
	const flattened: { node: T; depth: number; parents: T[] }[] = [];

	visitDepthFirst(
		node,
		(current, depth, parents) =>
			flattened.push({ node: current, depth, parents }),
		childrenAccessor,
		filterFn
	);

	return flattened;
}
