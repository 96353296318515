import { useEffect, useMemo } from "react";
import { WsEventTypes } from "../../../common/constants/wsEvents";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/default";
import { WsMessage } from "../../../core/socketProvider";
import wsPublisher$ from "../../../core/wsPublisher";
import { selectPricingSelectedGroupingFields } from "../../grouping/grouping.selector";
import { Pricing } from "../../pricing_list/pricingListSlice";
import { buildPredicates, setSelectedFilterValues } from "../../filters/utils";
import { mergePricings } from "../../grouping/grouping.slice";
import {
	selectPersistedFilters,
	selectPricingFilters,
} from "../../filters/filters.selector";

export function useMergePricingsIntoGroups(tenderId: number | undefined) {
	const dispatch = useAppDispatch();
	const selectedGroups = useAppSelector(selectPricingSelectedGroupingFields);
	const persistedFilters = useAppSelector(selectPersistedFilters);
	const pricingsFiltersData = useAppSelector(selectPricingFilters);
	const rootFiltersPredicates = useMemo(() => {
		const values = setSelectedFilterValues(
			pricingsFiltersData,
			persistedFilters.filters?.pricings_filters ?? ""
		);
		const predicates = buildPredicates(
			pricingsFiltersData,
			values,
			"Pricings"
		);
		return predicates.linqPredicates;
	}, [pricingsFiltersData, persistedFilters]);

	useEffect(() => {
		const onPricingChange = (messages: WsMessage<Pricing>[]) => {
			if (tenderId) {
				const pricingForThisTenderMessages = messages
					.filter((message) => message.data.tender_id === tenderId)
					.map((message) => message.data);
				if (pricingForThisTenderMessages?.length) {
					dispatch(
						mergePricings({
							pricings: pricingForThisTenderMessages,
							extraPredicates: rootFiltersPredicates,
						})
					);
				}
			}
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.PRICING_CREATED, WsEventTypes.PRICING_UPDATED],
			onPricingChange
		);
	}, [dispatch, selectedGroups, tenderId, rootFiltersPredicates]);
}
