import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import invariant from "tiny-invariant";

import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import type { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/types";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import * as liveRegion from "@atlaskit/pragmatic-drag-and-drop-live-region";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import Board from "./workflow_view/Board";
import {
	BoardContext,
	type BoardContextValue,
} from "./workflow_view/board-context";
import { Column } from "./workflow_view/Column";
import { createRegistry } from "./workflow_view/registry";
import {
	BoardState,
	ColumnMap,
	ColumnType,
	CompactTender,
	Outcome,
	Trigger,
} from "../tender.module";
import { TenderStatus } from "../../pricing_list/pricingListSlice";
import { useAppDispatch } from "../../../common/hooks/default";
import { getTendersForWorkflowView, updateTenderStatus } from "../tender.thunk";
import { getWorkflowViewInfo, TendersView } from "../tender.constant";
import { selectLoader, selectWorkflowList } from "../tender.selector";
import { useSelector } from "react-redux";
import { If } from "../../../common/components/If";
import { OverlayLoader } from "../../../common/components/OverlayLoader";
import * as _ from "lodash";
import { setColumnOrder, setTenderWorkflow } from "../tenderSlice";

export function getBasicData(data: ColumnMap) {
	const columnMap: ColumnMap = data;
	const orderedColumnIds = Object.values(TenderStatus);

	return {
		columnMap,
		orderedColumnIds,
	};
}

function WorkflowView() {
	const dispatch = useAppDispatch();
	const initTenders = useSelector(selectWorkflowList);
	const loading = useSelector(selectLoader(TendersView.WORKFLOW_VIEW));
	const [data, setData] = useState<BoardState>(() => {
		const base = getBasicData(initTenders);
		return {
			...base,
			lastOperation: null,
		};
	});

	const stableData = useRef(data);
	useEffect(() => {
		stableData.current = data;
	}, [data]);

	useEffect(() => {
		setData(() => {
			const base = getBasicData(initTenders);
			return {
				...base,
				lastOperation: null,
			};
		});
	}, [initTenders]);

	const [registry] = useState(createRegistry);

	const { lastOperation } = data;

	useEffect(() => {
		if (lastOperation === null) {
			return;
		}
		const { outcome, trigger } = lastOperation;

		if (outcome.type === "column-reorder") {
			const { startIndex, finishIndex } = outcome;

			const { columnMap, orderedColumnIds } = stableData.current;
			const sourceColumn = columnMap[orderedColumnIds[finishIndex]];

			const entry = registry.getColumn(sourceColumn.status);
			triggerPostMoveFlash(entry.element);

			liveRegion.announce(
				`You've moved ${
					getWorkflowViewInfo(sourceColumn.status).title
				} from position ${startIndex + 1} to position ${
					finishIndex + 1
				} of ${orderedColumnIds.length}.`
			);

			return;
		}

		if (outcome.type === "card-reorder") {
			const { columnId, startIndex, finishIndex } = outcome;

			const { columnMap } = stableData.current;
			const column = columnMap[columnId];
			const item = column.tenders[finishIndex];

			const entry = registry.getCard(item.id as number);
			triggerPostMoveFlash(entry.element);

			if (trigger !== "keyboard") {
				return;
			}

			liveRegion.announce(
				`You've moved ${item.name} from position ${
					startIndex + 1
				} to position ${finishIndex + 1} of ${
					column.tenders.length
				} in the ${getWorkflowViewInfo(column.status).title} column.`
			);

			return;
		}

		if (outcome.type === "card-move") {
			const {
				finishColumnId,
				itemIndexInStartColumn,
				itemIndexInFinishColumn,
			} = outcome;

			const currentData = stableData.current;
			const destinationColumn = currentData.columnMap[finishColumnId];
			const item = destinationColumn.tenders[itemIndexInFinishColumn];

			const finishPosition =
				typeof itemIndexInFinishColumn === "number"
					? itemIndexInFinishColumn + 1
					: destinationColumn.tenders.length;

			const entry = registry.getCard(item.id as number);
			triggerPostMoveFlash(entry.element);

			if (trigger !== "keyboard") {
				return;
			}

			liveRegion.announce(
				`You've moved ${item.name} from position ${
					itemIndexInStartColumn + 1
				} to position ${finishPosition} in the ${
					getWorkflowViewInfo(destinationColumn.status).title
				} column.`
			);

			return;
		}
	}, [lastOperation, registry]);

	useEffect(() => {
		if (!_.some(initTenders, "count")) {
			dispatch(getTendersForWorkflowView(Object.values(TenderStatus)));
		}
		return liveRegion.cleanup();
	}, []);

	const getColumns = useCallback(() => {
		const { columnMap, orderedColumnIds } = stableData.current;
		return orderedColumnIds.map((columnId) => columnMap[columnId]);
	}, []);

	const reorderColumn = useCallback(
		({
			startIndex,
			finishIndex,
			trigger = "keyboard",
		}: {
			startIndex: number;
			finishIndex: number;
			trigger?: Trigger;
		}) => {
			setData((prevData) => {
				const outcome: Outcome = {
					type: "column-reorder",
					columnId: prevData.orderedColumnIds[startIndex],
					startIndex,
					finishIndex,
				};

				return {
					...prevData,
					orderedColumnIds: reorder({
						list: prevData.orderedColumnIds,
						startIndex,
						finishIndex,
					}),
					lastOperation: {
						outcome,
						trigger: trigger,
					},
				};
			});
		},
		[]
	);

	const reorderCard = useCallback(
		({
			columnId,
			startIndex,
			finishIndex,
			trigger = "keyboard",
		}: {
			columnId: string;
			startIndex: number;
			finishIndex: number;
			trigger?: Trigger;
		}) => {
			setData((prevData) => {
				const sourceColumn = prevData.columnMap[columnId];
				const updatedItems = reorder({
					list: sourceColumn.tenders,
					startIndex,
					finishIndex,
				});

				const updatedSourceColumn: ColumnType = {
					...sourceColumn,
					tenders: updatedItems,
					order: undefined,
					orderColumn: undefined,
				};

				const updatedMap: ColumnMap = {
					...prevData.columnMap,
					[columnId]: updatedSourceColumn,
				};

				const outcome: Outcome | null = {
					type: "card-reorder",
					columnId,
					startIndex,
					finishIndex,
				};
				dispatch(setTenderWorkflow(updatedMap));
				return {
					...prevData,
					columnMap: updatedMap,
					lastOperation: {
						trigger: trigger,
						outcome,
					},
				};
			});
		},
		[]
	);

	const moveCard = useCallback(
		({
			startColumnId,
			finishColumnId,
			itemIndexInStartColumn,
			itemIndexInFinishColumn,
			trigger = "keyboard",
		}: {
			startColumnId: string;
			finishColumnId: string;
			itemIndexInStartColumn: number;
			itemIndexInFinishColumn?: number;
			trigger?: "pointer" | "keyboard";
		}) => {
			// invalid cross column movement
			if (startColumnId === finishColumnId) {
				return;
			}
			setData((prevData) => {
				const sourceColumn = prevData.columnMap[startColumnId];
				const destinationColumn = prevData.columnMap[finishColumnId];
				const item: CompactTender = _.cloneDeep(
					sourceColumn.tenders[itemIndexInStartColumn]
				);

				const destinationItems = Array.from(destinationColumn.tenders);
				// Going into the first position if no index is provided
				const newIndexInDestination = itemIndexInFinishColumn ?? 0;

				item.status = destinationColumn.status;
				destinationItems.splice(newIndexInDestination, 0, item as any);

				const updatedMap = {
					...prevData.columnMap,
					[startColumnId]: {
						...sourceColumn,
						count: sourceColumn.count - 1,
						tenders: sourceColumn.tenders.filter(
							(i: any) => i.id !== item.id
						),
						order: undefined,
						orderColumn: undefined,
					},
					[finishColumnId]: {
						...destinationColumn,
						count: destinationColumn.count + 1,
						tenders: destinationItems,
						order: undefined,
						orderColumn: undefined,
					},
				};

				const outcome: Outcome | null = {
					type: "card-move",
					finishColumnId,
					itemIndexInStartColumn,
					itemIndexInFinishColumn: newIndexInDestination,
				};
				if (item?.id)
					dispatch(updateTenderStatus(item.id, finishColumnId));
				dispatch(setTenderWorkflow(updatedMap));
				dispatch(setColumnOrder({ status: startColumnId }));
				dispatch(setColumnOrder({ status: finishColumnId }));
				return {
					...prevData,
					columnMap: updatedMap,
					lastOperation: {
						outcome,
						trigger: trigger,
					},
				};
			});
		},
		[]
	);

	const [instanceId] = useState(() => Symbol("instance-id"));

	useEffect(() => {
		return combine(
			monitorForElements({
				canMonitor({ source }) {
					return source.data.instanceId === instanceId;
				},
				onDrop(args) {
					const { location, source } = args;
					// didn't drop on anything
					if (!location.current.dropTargets.length) {
						return;
					}
					// need to handle drop

					// 1. remove element from original position
					// 2. move to new position

					if (source.data.type === "column") {
						const startIndex: number =
							data.orderedColumnIds.findIndex(
								(columnId) => columnId === source.data.columnId
							);

						const target = location.current.dropTargets[0];
						const indexOfTarget: number =
							data.orderedColumnIds.findIndex(
								(id) => id === target.data.columnId
							);
						const closestEdgeOfTarget: Edge | null =
							extractClosestEdge(target.data);

						const finishIndex = getReorderDestinationIndex({
							startIndex,
							indexOfTarget,
							closestEdgeOfTarget,
							axis: "horizontal",
						});

						reorderColumn({
							startIndex,
							finishIndex,
							trigger: "pointer",
						});
					}
					// Dragging a card
					if (source.data.type === "card") {
						const itemId = source.data.itemId;
						invariant(typeof itemId === "number");
						// TODO: these lines not needed if item has columnId on it
						const [, startColumnRecord] =
							location.initial.dropTargets;
						const sourceId = startColumnRecord.data.columnId;
						invariant(typeof sourceId === "string");
						const sourceColumn = data.columnMap[sourceId];
						const itemIndex = sourceColumn.tenders.findIndex(
							(item: any) => item.id === itemId
						);

						if (location.current.dropTargets.length === 1) {
							const [destinationColumnRecord] =
								location.current.dropTargets;
							const destinationId =
								destinationColumnRecord.data.columnId;
							invariant(typeof destinationId === "string");
							const destinationColumn =
								data.columnMap[destinationId];
							invariant(destinationColumn);

							// reordering in same column
							if (sourceColumn === destinationColumn) {
								const destinationIndex =
									getReorderDestinationIndex({
										startIndex: itemIndex,
										indexOfTarget:
											sourceColumn.tenders.length - 1,
										closestEdgeOfTarget: null,
										axis: "vertical",
									});
								reorderCard({
									columnId: sourceColumn.status,
									startIndex: itemIndex,
									finishIndex: destinationIndex,
									trigger: "pointer",
								});
								return;
							}

							// moving to a new column
							moveCard({
								itemIndexInStartColumn: itemIndex,
								startColumnId: sourceColumn.status,
								finishColumnId: destinationColumn.status,
								trigger: "pointer",
							});
							return;
						}

						// dropping in a column (relative to a card)
						if (location.current.dropTargets.length === 2) {
							const [
								destinationCardRecord,
								destinationColumnRecord,
							] = location.current.dropTargets;
							const destinationColumnId =
								destinationColumnRecord.data.columnId;
							invariant(typeof destinationColumnId === "string");
							const destinationColumn =
								data.columnMap[destinationColumnId];

							const indexOfTarget =
								destinationColumn.tenders.findIndex(
									(item: any) =>
										item.id ===
										destinationCardRecord.data.itemId
								);
							const closestEdgeOfTarget: Edge | null =
								extractClosestEdge(destinationCardRecord.data);

							// case 1: ordering in the same column
							if (sourceColumn === destinationColumn) {
								const destinationIndex =
									getReorderDestinationIndex({
										startIndex: itemIndex,
										indexOfTarget,
										closestEdgeOfTarget,
										axis: "vertical",
									});
								reorderCard({
									columnId: sourceColumn.status,
									startIndex: itemIndex,
									finishIndex: destinationIndex,
									trigger: "pointer",
								});
								return;
							}

							// case 2: moving into a new column relative to a card

							const destinationIndex =
								closestEdgeOfTarget === "bottom"
									? indexOfTarget + 1
									: indexOfTarget;

							moveCard({
								itemIndexInStartColumn: itemIndex,
								startColumnId: sourceColumn.status,
								finishColumnId: destinationColumn.status,
								itemIndexInFinishColumn: destinationIndex,
								trigger: "pointer",
							});
						}
					}
				},
			})
		);
	}, [data, instanceId, moveCard, reorderCard, reorderColumn]);

	const contextValue: BoardContextValue = useMemo(() => {
		return {
			getColumns,
			reorderColumn,
			reorderCard,
			moveCard,
			registerCard: registry.registerCard,
			registerColumn: registry.registerColumn,
			instanceId,
		};
	}, [
		getColumns,
		reorderColumn,
		reorderCard,
		registry,
		moveCard,
		instanceId,
	]);

	return (
		<BoardContext.Provider value={contextValue}>
			<If condition={loading}>
				<OverlayLoader />
			</If>
			<If condition={!loading}>
				<Board>
					{data.orderedColumnIds.map((columnId) => {
						return (
							<Column
								column={data.columnMap[columnId]}
								key={columnId}
							/>
						);
					})}
				</Board>
			</If>
		</BoardContext.Provider>
	);
}

export default memo(WorkflowView);
