import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/default";
import { reloadPricingsGroupCount } from "../../grouping/grouping.thunk";
import { selectPricingSelectedGroupingFields } from "../../grouping/grouping.selector";
import usePrevious from "../../../common/hooks/usePrevious";

export function useReloadGroups(tenderId?: number, odataQuery?: string) {
	const dispatch = useAppDispatch();
	const selectedGroups = useAppSelector(selectPricingSelectedGroupingFields);
	const previousOdataQuery = usePrevious(odataQuery);

	useEffect(() => {
		if (tenderId && previousOdataQuery !== odataQuery) {
			dispatch(reloadPricingsGroupCount(tenderId, odataQuery));
		}
	}, [selectedGroups, dispatch, tenderId, odataQuery, previousOdataQuery]);
}
