import React, { useCallback, useState } from "react";
import { Column } from "../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../pricing_list/hooks/columns/common";
import { borderGrey } from "../../core/theme";
import {
	Box,
	Alert,
	LinearProgress,
	TableHead,
	TableBody,
	Table,
	TableContainer,
	TableRow,
} from "@mui/material";
import { If } from "../../common/components/If";
import { useAppDispatch, useAppSelector } from "../../common/hooks/default";
import {
	selectPricingsGroupCount,
	selectPricingsGroupCountIsLoading,
	selectPricingsGroupCountError,
	selectFlattenedGroups,
} from "../grouping/grouping.selector";
import { useReloadGroupCountOnPricingWs } from "./hooks/useReloadGroupCountOnPricingWs";
import { useInitialGroupLoading } from "./hooks/useInitialGroupLoading";
import { TableVirtuoso } from "react-virtuoso";
import { GroupedPricingListRow } from "./GroupedPricingListRow";
import { expandGroup, selectGroup } from "../grouping/grouping.slice";
import { groupRowKey, isGroup, isPricing, isPricingRun } from "./groupUtils";
import { loadGroupPricings } from "../grouping/grouping.thunk";
import { PricingTableHeader } from "./rows/PricingTableHeader";
import { useReloadGroups } from "./hooks/useReloadGroups";
import { useMergePricingsIntoGroups } from "./hooks/useMergePricingsIntoGroups";
import { Action, Pricing } from "../pricing_list/pricingListSlice";
import GraphModal from "../pricing_list/components/actionModal/GraphModal";
import { ReadCommentModal } from "../pricing_list/components/ReadCommentModal";
import { useSelector } from "react-redux";
import { useForceReloadOnTenderChanges } from "./hooks/useForceReloadOnTenderChanges";

export interface PricingGroupTableGroupsProps {
	tenderId?: number;
	odataQuery?: string;
	selectedColumns: Column<ColumnIds>[];
	clearSelection: () => void;
	availableActions: Action[];
	selectAll: () => void;
	actOnPricings: (action: Action) => void;
	onDownloadSelectedRunsToExcel: () => void;
	onPricingsDownload: () => void;
	onDownloadRunToExcelByRunIds: (ids: number[]) => void;
	onDownloadPbParams: (pricingRunId: number) => void;
	onDownloadJson: (id: number) => void;
	actionModal: React.ReactNode;
}

const styles = {
	wrapper: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		marginTop: "40px",
		borderTop: `1px solid ${borderGrey}`,
		height: "100%",
		overflowY: "scroll",
	},
	fixed: {
		tableLayout: "fixed",
	},
};

const TableComponents = {
	Scroller: React.forwardRef(function TScroller(props: any, ref: any) {
		return <TableContainer {...props} ref={ref} />;
	}),
	Table: (props: any) => (
		<Table
			{...props}
			style={{ borderCollapse: "separate", width: "100%" }}
		/>
	),
	TableHead: TableHead,
	TableRow: TableRow,
	TableBody: React.forwardRef(function TBody(props: any, ref) {
		return <TableBody {...props} ref={ref} />;
	}),
};

export function PricingGroupTable(props: PricingGroupTableGroupsProps) {
	const {
		clearSelection,
		availableActions,
		selectAll,
		actOnPricings,
		onDownloadSelectedRunsToExcel,
		onPricingsDownload,
		onDownloadRunToExcelByRunIds,
		onDownloadPbParams,
		onDownloadJson,
		actionModal,
	} = props;

	const dispatch = useAppDispatch();
	const [selectedPricing, setSelectedPricing] = useState<Pricing | undefined>(
		undefined
	);
	const [comment, setComment] = useState<string | undefined>(undefined);
	const pricingsGroupsCount = useAppSelector(selectPricingsGroupCount);
	const groupCountIsLoading = useAppSelector(
		selectPricingsGroupCountIsLoading
	);
	const groupCountError = useAppSelector(selectPricingsGroupCountError);

	useInitialGroupLoading(props.tenderId, props.odataQuery);
	useReloadGroups(props.tenderId, props.odataQuery);
	useReloadGroupCountOnPricingWs(props.tenderId, props.odataQuery);
	useForceReloadOnTenderChanges(props.tenderId, props.odataQuery);
	useMergePricingsIntoGroups(props.tenderId);

	const flattened = useSelector(selectFlattenedGroups);

	const onGroupSelection = useCallback(
		(groupPath: string[]) => {
			dispatch(selectGroup({ groupPath }));
		},
		[dispatch]
	);

	const onLoadPricings = useCallback(
		(groupPath: string[]) => {
			dispatch(loadGroupPricings(props.tenderId, groupPath));
		},
		[dispatch]
	);

	const onGroupExpand = useCallback(
		(groupPath: string[]) => {
			dispatch(expandGroup({ groupPath }));
		},
		[dispatch]
	);

	const openGraphModal = (pricing: Pricing | undefined) => {
		setSelectedPricing(pricing);
	};

	const displayComment = useCallback(
		(status_comment: string) => {
			setComment(status_comment);
		},
		[setComment]
	);

	return (
		<Box sx={styles.wrapper}>
			<If condition={groupCountError}>
				<Alert severity="error">
					{"" + JSON.stringify(groupCountError)}
				</Alert>
			</If>
			<If condition={groupCountIsLoading}>
				<LinearProgress />
			</If>
			<If
				condition={!groupCountIsLoading && !pricingsGroupsCount?.length}
			>
				<Alert severity="warning">No items found</Alert>
			</If>
			<TableVirtuoso
				components={TableComponents as any}
				fixedHeaderContent={() => (
					<PricingTableHeader
						selectedColumns={props.selectedColumns}
						clearPricingSelection={clearSelection}
						availableActionsForSelection={availableActions}
						selectAll={selectAll}
						onAction={actOnPricings}
						onDownloadRunToExcel={onDownloadSelectedRunsToExcel}
						onPricingsDownload={onPricingsDownload}
					/>
				)}
				data={flattened}
				computeItemKey={(index, item) =>
					groupRowKey(item.node, item.parents)
				}
				itemContent={(index, item) => (
					<GroupedPricingListRow
						group={isGroup(item.node) ? item.node : undefined}
						pricing={isPricing(item.node) ? item.node : undefined}
						pricingRun={
							isPricingRun(item.node) ? item.node : undefined
						}
						depth={item.depth}
						parents={item.parents}
						onGroupSelection={onGroupSelection}
						onGroupExpand={onGroupExpand}
						onLoadPricings={onLoadPricings}
						selectedColumns={props.selectedColumns}
						onDisplayComment={displayComment}
						onSubmit={function (id: number, action: Action): void {
							actOnPricings(action);
						}}
						onDownloadRunToExcel={onDownloadRunToExcelByRunIds}
						onDownloadPbParams={onDownloadPbParams}
						onDownloadJson={onDownloadJson}
						openGraphModal={openGraphModal}
						openWarningModal={displayComment}
					/>
				)}
			/>
			{actionModal}
			<If condition={!!selectedPricing}>
				<GraphModal
					open={!!selectedPricing}
					onClose={() => setSelectedPricing(undefined)}
					pricing={selectedPricing}
				/>
			</If>
			<ReadCommentModal
				isOpen={!!comment}
				comment={comment}
				onClose={() => setComment(undefined)}
			/>
		</Box>
	);
}
