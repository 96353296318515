import { GroupedField, groupId } from "../grouping/grouping.module";
import { Pricing, PricingRun } from "../pricing_list/pricingListSlice";

export type PricingListItem = GroupedField | Pricing | PricingRun;

export function isGroup(item: PricingListItem): item is GroupedField {
	return !!(item as GroupedField)?.field_key;
}

export function isPricing(item: PricingListItem): item is Pricing {
	return !!(item as Pricing)?.pricing_type;
}

export function isPricingRun(item: PricingListItem): item is PricingRun {
	return !!(item as PricingRun)?.summary;
}

export function childrenAccessor(item: PricingListItem) {
	if (isGroup(item)) {
		if (item.pricings?.length) {
			return [...item.pricings].reverse();
		}
		return item.children || [];
	} else if (isPricing(item) && item.pricing_runs?.length > 1) {
		return item.pricing_runs.slice(1);
	}
	return [];
}

export function groupOfPricingFilter(item: PricingListItem) {
	if (isGroup(item)) {
		return !!item.isExpanded;
	}
	if (isPricing(item)) {
		return !!item.isExpanded;
	}
	return false;
}

export function isLeaf(group: GroupedField) {
	return !group.children?.length;
}

export function isLastLineOfGroup(
	line: PricingListItem,
	parents: PricingListItem[]
) {
	if (isGroup(line)) {
		return false;
	}
	const lastParent = parents[parents.length - 1];
	if (!isGroup(lastParent)) {
		return false;
	}
	const lastPricing =
		lastParent?.pricings?.[(lastParent.pricings?.length || 0) - 1];
	return lastPricing?.id === line.id;
}

function singleGroupRowKey(item: PricingListItem) {
	if (isGroup(item)) {
		return groupId(item);
	} else if (isPricing(item)) {
		return "p_id_" + item.id;
	}
	return "pr_id" + item.id;
}

export function groupRowKey(item: PricingListItem, parents: PricingListItem[]) {
	return parents
		.map(singleGroupRowKey)
		.concat([singleGroupRowKey(item)])
		.join("-");
}
