import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Action, Pricing } from "../../pricingListSlice";
import { useTemporaryFetch } from "../../../../common/hooks/useTemporaryFetch";
import { PaginatedResponse } from "../../../../common/interfaces";
import * as odata from "../../../../common/utils/odata";
import { apiGet, apiPatch } from "../../../../common/utils/request.utils";
import wsPublisher$ from "../../../../core/wsPublisher";
import { WsEventTypes } from "../../../../common/constants/wsEvents";
import { If } from "../../../../common/components/If";
import { useAppDispatch } from "../../../../common/hooks/default";
import usePrevious from "../../../../common/hooks/usePrevious";
import { saveObjectAsJSON } from "../../../../common/utils/downloadJson";
import ActionPricingModal from "../../components/actionModal/ActionPricingModal";
import AddPartyModal from "../../components/actionModal/AddPartyModal";
import { AddRealizedModal } from "../../components/actionModal/AddRealizedModal";
import { AddRealizedPortModal } from "../../components/actionModal/AddRealizedPortModal";
import ErrorModal from "../../components/actionModal/ErrorModal";
import { SetFixingActionModal } from "../../components/actionModal/SetFixingActionModal";
import BookingModal from "../../components/BookDialog";
import { ACTION_PRICING_ERROR, PricingError } from "../../pricingList.constant";
import {
	downloadPricingRunsExport,
	donwloadPricingPbInputs,
	downloadPricingsExport,
} from "../../pricingList.thunk";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import {
	selectSelectedGroups,
	selectSelectedPricing,
} from "../../../grouping/grouping.selector";
import { ValueOrPercentContext } from "../../ValueOrPercentContext";
import {
	clearGroupsAndPricingsSelection,
	selectAllGroups,
	statusEndLoading,
	statusStartLoading,
} from "../../../grouping/grouping.slice";
import { mapToQueryString } from "../../../../common/utils/querystring";
import { filterActions } from "./actions.utils";
import { selectPersistedFilters } from "../../../filters/filters.selector";

function actionHasFields(action?: Action | null) {
	return !!Object.keys(action?.fields || {})?.length;
}

function actionNeedsModal(action?: Action | null) {
	return actionHasFields(action) || action?.action === "BOOK";
}

const ACTIONS_WITH_CUSTOM_MODALS = [
	"ADD_REALIZED",
	"ADD_PARTY",
	"BOOK",
	"FIX_PRICE",
];

export function usePricingActionsV2(tenderId?: number) {
	/**
	 * Actions can be triggered on a group of pricings by calling the endpoint /update-pricings/{transition}
	 * with an odata query.
	 *
	 * The backend already sends us a list of available actions
	 *   - on each pricing
	 *   - on each group
	 *   - on the whole tender
	 *
	 * We should use this information to avoid presenting the user with actions he/she cannot perform.
	 *
	 * A group of pricing that'll be acted on can always be thought of as a (moderatly complicated) filter.
	 * For example:
	 *    - (a) every pricing which delivery is CAL25
	 *          => delivery_period in ('CAL25',)
	 *    - (b) also we manually selected pricings 78, 79 and 80              \
	 *          => id in (78, 79, 80)
	 *    - (c) every pricing which has cal26 and where ths site is molau:
	 *          => site_or_portfolio in ('[sites]Molau',) AND delivery_period in ('CAL26',)
	 *
	 * Then we can act on all those pricings at once by sending the following filter to the backend: (a) OR (b) OR (c).
	 * It is convenient to use filters for these, because we can use the same query for getting them and presenting them to the user.
	 * We have 3 scenarios for pricing actions
	 *
	 * (A) Tender level action: all pricings on this tender satisfying a condition should be acted on
	 * (B) Batch action: all pricings selected should be acted on
	 * (C) Group action: every pricing inside a group should be acted on
	 *
	 * Overall workflow:
	 *
	 *     (1)                    (2)                    (3)
	 *      A                      B                      C
	 *      |                      |                      |
	 * action.filter           id in (xxx)             group.filters
	 *      |                      |                      |
	 *      |                      |                      |
	 *      |                      |                      |
	 *      ----------->Fetch all involved pricings<-------
	 *                             |
	 *                             v
	 *                 does the action need a modal ? --YES--> Open action modal . . > cancel action (4)
	 *                             |                                .
	 *                             NO                               .
	 *                             |                                .
	 *                             v                                v
	 *                       Submit action  <................Submit action modal (5)
	 *                             |
	 *                             |
	 *                             V
	 *                   Update pricings locally
	 *
	 * B and C may be mixed together.
	 * - selectedPricings argument would be case (B)
	 * - selectedGroups would be case (C)
	 * - staticFilters would be case (D)
	 *
	 */
	const dispatch = useAppDispatch();
	const persistedFilters = useSelector(selectPersistedFilters, _.isEqual);
	const selectedGroups = useSelector(selectSelectedGroups);
	const selectedPricings = useSelector(selectSelectedPricing);
	const formatContext = useContext(ValueOrPercentContext);
	const onSuccess = useRef<() => void | undefined>();
	const [pricingAction, setPricingAction] = useState<Action | null>();
	const [isTransitionning, setIsTransitionning] = useState(false);
	const [error, setError] = useState<any>(undefined);
	const {
		data: pricingsUnderActionResponse,
		isLoading: arePricingsUnderActionLoading,
		fetchTemporaryData: fetchPricingsUnderAction,
		clearTemporaryData: clearPricingsUnderAction,
	} = useTemporaryFetch<PaginatedResponse<Pricing>>(
		(response) => response.data
	);
	const wasTransitionLoading = usePrevious(isTransitionning);
	const [tenderAction, setTenderAction] = useState<
		Action | null | undefined
	>();

	const action = useMemo(
		() => tenderAction || pricingAction,
		[tenderAction, pricingAction]
	);

	const pricingsUnderAction = useMemo(
		() => pricingsUnderActionResponse?.data || [],
		[pricingsUnderActionResponse]
	);

	const completeFilters = useMemo(() => {
		const segments = [];
		if (selectedPricings && selectedPricings.length) {
			segments.push(
				odata.fieldIn(
					"id",
					selectedPricings.map((pricing) => pricing.id)
				)
			);
		}
		if (selectedGroups && selectedGroups.length) {
			selectedGroups.forEach((group) => {
				segments.push(odata.computedFiltersToOdata(group.filters));
			});
		}
		if (persistedFilters.filters?.pricings_query) {
			return odata.and([
				// slice is for removing "
				persistedFilters.filters?.pricings_query.slice(1, -1),
				odata.or(segments),
			]);
		} else {
			return odata.or(segments);
		}
	}, [selectedPricings, selectedGroups, persistedFilters]);

	const params = useMemo(() => {
		return {
			filters: completeFilters,
			tender_id: "" + tenderId,
		};
	}, [completeFilters, tenderId]);

	const actOnPricings = useCallback(
		(newAction: Action, callback?: () => void) => {
			onSuccess.current = callback;
			setPricingAction(newAction); // this actually opens the modal
			fetchPricingsUnderAction("pricing?" + mapToQueryString(params));
		},
		[completeFilters, setPricingAction]
	);

	const actOnTender = useCallback(
		(newAction: Action) => {
			setTenderAction(newAction);
			// we don't use complete filter here, because we want to act on the whole tender
			fetchPricingsUnderAction(
				"pricing?" +
					mapToQueryString({
						tender_id: tenderId,
						filters: newAction.filters,
					})
			);
		},
		[setTenderAction]
	);

	const isActionModalOpen = useMemo(
		() => actionHasFields(action) || action?.action === "BOOK",
		[action]
	);

	const isActionLoading = useMemo(
		() => arePricingsUnderActionLoading || isTransitionning,
		[arePricingsUnderActionLoading, isTransitionning]
	);

	const clear = useCallback(() => {
		setPricingAction(null);
		setTenderAction(null);
		clearPricingsUnderAction();
		setError(null);
		dispatch(clearGroupsAndPricingsSelection());
	}, [
		setPricingAction,
		setTenderAction,
		clearPricingsUnderAction,
		setError,
		dispatch,
	]);

	const submit = useCallback(
		async (
			values: any = {},
			pricingPartyIds: { id: number; party_id: number }[] = []
		) => {
			const baseFilters = tenderAction
				? // slice is for removing quotes
				  tenderAction.filters?.slice(1, -1) || ""
				: params.filters;
			const filters = !!pricingPartyIds?.length
				? {
						...params,
						filters: odata.and([
							baseFilters || "",
							odata.fieldIn(
								"id",
								pricingPartyIds.map((it) => it.id)
							),
						]),
				  }
				: params;
			try {
				dispatch(statusStartLoading());
				setIsTransitionning(true);
				if (!!pricingPartyIds) {
					values.party_ids = pricingPartyIds;
				}
				const response = await apiPatch(
					(action?.endpoint as string) +
						"?" +
						mapToQueryString(filters),
					values
				);
				const updatedPricings = response?.data.data || [];
				updatedPricings.forEach((pricing: Pricing) => {
					wsPublisher$.publish({
						type: WsEventTypes.PRICING_UPDATED,
						data: {
							...pricing,
							isNewUpdate: true,
						},
						at: new Date().toJSON(),
					});
				});
				if (onSuccess.current) {
					onSuccess.current();
				}
				setError(undefined);
				clear();
			} catch (err: any) {
				setIsTransitionning(false);
				setError(err?.response?.data);
			} finally {
				setIsTransitionning(false);
				setPricingAction(null);
				setTenderAction(null);
				dispatch(statusEndLoading());
			}
		},
		[pricingsUnderActionResponse, action, dispatch]
	);

	useEffect(() => {
		if (action && !actionNeedsModal(action)) {
			submit();
		}
	}, [action]);

	const submitActionModal = useCallback(
		(
			values: any,
			pricingPartyIds: { id: number; party_id: number }[] = []
		) => {
			submit(values, pricingPartyIds);
		},
		[submit]
	);

	const cancelActionModal = useCallback(() => {
		if (!isTransitionning && !arePricingsUnderActionLoading) {
			clear();
		}
	}, [clear, isTransitionning, arePricingsUnderActionLoading]);

	useEffect(() => {
		// clear modal after submit is successful
		if (
			wasTransitionLoading &&
			!isTransitionning &&
			actionNeedsModal(action) &&
			!error
		) {
			clear();
		}
	}, [wasTransitionLoading, isTransitionning, actionNeedsModal, error]);

	const errorMessage = useMemo(() => {
		if (error?.errors?.[0] in ACTION_PRICING_ERROR) {
			return ACTION_PRICING_ERROR[error?.errors[0] as PricingError];
		} else if (error?.errors?.[0] && error?.errors?.[0]?.msg) {
			return error?.errors?.[0]?.msg;
		} else if (error) {
			return "Unknwon error" + error;
		}
	}, [error]);

	const availableActions = useMemo(() => {
		const actions = _.intersectionBy(
			...selectedPricings
				.map((pricing) => pricing.actions)
				.concat(selectedGroups.map((group) => group.actions)),
			"action"
		);
		if (!selectAllGroups.length) {
			return actions;
		}
		return filterActions(selectedPricings, actions);
	}, [selectedGroups, selectedPricings]);

	const isModalLoading = useMemo(
		() => isActionLoading || arePricingsUnderActionLoading,
		[isActionLoading, arePricingsUnderActionLoading]
	);

	const actionModal = useMemo(
		() => (
			<>
				<If
					condition={
						!ACTIONS_WITH_CUSTOM_MODALS.includes(
							action?.action || ""
						)
					}
				>
					<ActionPricingModal
						open={
							isActionModalOpen &&
							!!action?.action &&
							!["BOOK", "ADD_PARTY"].includes(action?.action)
						}
						action={action}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) => submitActionModal(body)}
						onClose={cancelActionModal}
						isLoading={isModalLoading}
						hasMorePricings={
							!!pricingsUnderActionResponse?.has_next_page
						}
					/>
				</If>
				<If
					condition={
						pricingsUnderAction[0]?.portfolio_id &&
						action?.action === "ADD_REALIZED"
					}
				>
					<AddRealizedPortModal
						open={
							isActionModalOpen &&
							action?.action === "ADD_REALIZED"
						}
						action={action}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) => submitActionModal(body)}
						onClose={cancelActionModal}
						isLoading={isModalLoading}
					/>
				</If>
				<If
					condition={
						!pricingsUnderAction[0]?.portfolio_id &&
						action?.action === "ADD_REALIZED"
					}
				>
					<AddRealizedModal
						open={
							isActionModalOpen &&
							action?.action === "ADD_REALIZED"
						}
						action={action}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) => submitActionModal(body)}
						onClose={cancelActionModal}
						isLoading={isModalLoading}
					/>
				</If>
				<If condition={action?.action === "ADD_PARTY"}>
					<AddPartyModal
						open={
							isActionModalOpen && action?.action === "ADD_PARTY"
						}
						action={action}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) => submitActionModal(body)}
						onClose={cancelActionModal}
						isLoading={isModalLoading}
					/>
				</If>
				<If condition={action?.action === "BOOK"}>
					<BookingModal
						open={isActionModalOpen && action?.action === "BOOK"}
						isLoading={isModalLoading}
						forPricings={pricingsUnderAction || []}
						onClose={cancelActionModal}
						onSubmit={(values, pricingIds, pricingPartyIds) => {
							submitActionModal(values, pricingPartyIds);
						}}
					/>
				</If>

				<If condition={action?.action === "FIX_PRICE"}>
					<SetFixingActionModal
						open={
							isActionModalOpen && action?.action === "FIX_PRICE"
						}
						isLoading={isModalLoading}
						forPricings={pricingsUnderAction || []}
						onClose={cancelActionModal}
						onSubmit={(body) => submitActionModal(body)}
						errorMessage={error}
					/>
				</If>

				<ErrorModal
					message={errorMessage}
					open={!!error}
					onClose={() => {
						setError(undefined);
					}}
					pricingsIds={error?.ids}
				/>
			</>
		),
		[
			isActionModalOpen,
			action,
			pricingsUnderActionResponse,
			submitActionModal,
			cancelActionModal,
			isActionLoading,
			errorMessage,
		]
	);

	const onDownloadRunToExcelByRunIds = useCallback(
		(ids: number[]) => {
			dispatch(downloadPricingRunsExport(odata.fieldIn("id", ids)));
		},
		[dispatch]
	);

	const onDownloadSelectedRunsToExcel = useCallback(() => {
		dispatch(downloadPricingRunsExport(completeFilters));
	}, [dispatch, completeFilters]);

	const onDownloadPbParams = useCallback(
		(pricingRunId: number) => {
			dispatch(donwloadPricingPbInputs(pricingRunId));
		},
		[dispatch]
	);

	const onDownloadJson = useCallback(
		(id: number) => {
			apiGet(`pricing-run?pricing_run_id=${id}`).then((response) => {
				saveObjectAsJSON(`${id}-inputs`, response.data);
			});
		},
		[dispatch]
	);

	const onPricingsDownload = useCallback(() => {
		dispatch(
			downloadPricingsExport(
				tenderId || 0,
				[],
				formatContext,
				completeFilters
			)
		);
	}, [dispatch]);

	const clearSelection = useCallback(() => {
		dispatch(clearGroupsAndPricingsSelection());
	}, [dispatch]);

	const selectAll = useCallback(() => {
		dispatch(selectAllGroups());
	}, [dispatch]);

	return useMemo(
		() => ({
			actOnPricings,
			actionModal,
			onDownloadRunToExcelByRunIds,
			onDownloadSelectedRunsToExcel,
			onDownloadPbParams,
			onDownloadJson,
			onPricingsDownload,
			availableActions,
			clearSelection,
			selectAll,
			actOnTender,
		}),
		[
			actOnPricings,
			actionModal,
			onDownloadRunToExcelByRunIds,
			onDownloadSelectedRunsToExcel,
			onDownloadPbParams,
			onDownloadJson,
			onPricingsDownload,
			availableActions,
			clearSelection,
			selectAll,
			actOnTender,
		]
	);
}
