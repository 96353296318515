import React, { useCallback, useContext, useMemo } from "react";
import { GroupedField, groupId } from "../grouping/grouping.module";
import { GroupLine } from "./rows/GroupLine";
import { Action, Pricing, PricingRun } from "../pricing_list/pricingListSlice";
import {
	PricingListItem,
	isGroup,
	isLastLineOfGroup,
	isLeaf,
	isPricing,
} from "./groupUtils";
import { PricingLine } from "./rows/PricingLine";
import { ColumnIds } from "../pricing_list/hooks/columns/common";
import { Column } from "../../common/components/ColumnsSelector/ColumnsSelector";
import { Waypoint } from "react-waypoint";
import { loadGroupPricingsNextPage } from "../grouping/grouping.thunk";
import { useAppDispatch } from "../../common/hooks/default";
import { PricingRunLine } from "./rows/PricingRunLine";
import { ValueOrPercentContext } from "../pricing_list/ValueOrPercentContext";

export interface GroupedPricingListRowProps {
	group?: GroupedField;
	pricing?: Pricing;
	pricingRun?: PricingRun;
	depth: number;
	parents: PricingListItem[];
	onGroupSelection: (groupPath: string[]) => void;
	onGroupExpand: (groupPath: string[]) => void;
	onLoadPricings: (groupPath: string[]) => void;
	selectedColumns: Column<ColumnIds>[];
	onDisplayComment: (comment: string) => void;
	onSubmit: (id: number, action: Action) => void;
	onDownloadRunToExcel: (ids: number[]) => void;
	onDownloadPbParams: (id: number) => void;
	onDownloadJson: (id: number) => void;
	openGraphModal: (pricing: Pricing | undefined) => void;
	openWarningModal: (message: string) => void;
}

function GroupedPricingListRowComponent(props: GroupedPricingListRowProps) {
	const dispatch = useAppDispatch();
	const onLoadNextPage = useCallback(() => {
		if (props.pricing) {
			const groupPath = props.parents.filter(isGroup).map(groupId);
			dispatch(
				loadGroupPricingsNextPage(props.pricing.tender_id, groupPath)
			);
		}
	}, [dispatch, props.pricing?.tender_id]);

	// the spinner and loader for pricing lines are computed here because
	// this avoids sending the parents array down
	const shouldSpin = useMemo(() => {
		if (!props.pricing) {
			if (props.group && props.group.pricingsAreLoading) {
				return (
					!props.group.pricings || props.group.pricings?.length === 0
				);
			}
		} else if (props.pricing && props.pricing.isLoadingRuns) {
			return true;
		} else {
			if (!isLastLineOfGroup(props.pricing, props.parents)) {
				return false;
			}
			const lastParent = props.parents[props.parents.length - 1];
			if (!isGroup(lastParent)) {
				return false;
			}
			return !!lastParent.pricingsAreLoading;
		}
	}, [props.pricing, props.parents]);

	const lazyLoadObserver = useMemo(() => {
		if (!props.pricing) {
			return null;
		}
		if (!isLastLineOfGroup(props.pricing, props.parents)) {
			return null;
		}
		const lastParent = props.parents[props.parents.length - 1];
		if (isGroup(lastParent) && lastParent.thereAreMorePricingsToLoad) {
			return <Waypoint onEnter={onLoadNextPage} />;
		}
		return null;
	}, [onLoadNextPage, props.pricing, props.parents]);

	const isSelectionDisabled = useMemo(() => {
		return props.parents
			.filter(isGroup)
			.reduce((acc, curr) => acc || !!curr.isSelected, false);
	}, [props.parents]);

	const valueOrPercent = useContext(ValueOrPercentContext);

	if (props.group) {
		return (
			<>
				<GroupLine
					groupedField={props.group}
					depth={props.depth}
					parents={props.parents.filter(isGroup)}
					onGroupSelection={props.onGroupSelection}
					onGroupExpand={(groupPath: string[]) => {
						props.onGroupExpand(groupPath);
						if (props?.group && isLeaf(props?.group)) {
							props.onLoadPricings(groupPath);
						}
					}}
					shouldSpin={shouldSpin}
					selectedColumns={props.selectedColumns}
				/>
			</>
		);
	}
	if (props.pricing) {
		return (
			<>
				<PricingLine
					pricing={props.pricing}
					valueOrPercent={valueOrPercent}
					onDisplayComment={props.onDisplayComment}
					onDownloadRunToExcel={props.onDownloadRunToExcel}
					onDownloadPbParams={props.onDownloadPbParams}
					onDownloadJson={props.onDownloadJson}
					openGraphModal={props.openGraphModal}
					openWarningModal={props.openWarningModal}
					selectedColumns={props.selectedColumns}
					isSelectionDisabled={isSelectionDisabled}
					shouldSpin={shouldSpin}
					statusIsLoading={props.pricing?.isStatusUpdating}
				/>
				{lazyLoadObserver}
			</>
		);
	}
	const parentPricing = props.parents.slice(-1)[0];
	if (props.pricingRun && isPricing(parentPricing)) {
		return (
			<>
				<PricingRunLine
					run={props.pricingRun}
					pricing={parentPricing}
					valueOrPercent={valueOrPercent}
					onDownloadRunToExcel={props.onDownloadRunToExcel}
					onDownloadJson={props.onDownloadJson}
					openGraphModal={props.openGraphModal}
					openWarningModal={props.openWarningModal}
					selectedColumns={props.selectedColumns}
				/>
			</>
		);
	}
	return <></>;
}

export const GroupedPricingListRow = React.memo(GroupedPricingListRowComponent);
