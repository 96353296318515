import { createSlice } from "@reduxjs/toolkit";
import { Tender } from "./tender.module";
import uniqBy from "lodash.uniqby";
import { Partial } from "rsuite/esm/@types/utils";
import { TenderStatus } from "../pricing_list/pricingListSlice";
import * as _ from "lodash";
import { Order } from "./tender.constant";
import { OrderColumn } from "./utils/workflowView.util";

export interface Account {
	accountId: number;
	accountName: string;
}

export interface TenderWorkflowView {
	status: TenderStatus;
	count: number;
	tenders: Partial<Tender>[];
	order?: Order;
	orderColumn?: OrderColumn;
}

interface TenderState {
	addedTenderCount: number;
	latestTenderId?: number;
	latestUpdatedAt?: Date;
	isPaginationStopped: boolean;
	tenders: Tender[];

	workflowView: Record<TenderStatus, TenderWorkflowView>;

	accounts: Account[];
	tsImporter?: string[];
	loader: {
		tender: boolean;
		pricingsByTender: Record<number, boolean>;
		actions: boolean;
		tsImporter: boolean;
		workflowView: boolean;
	};
	errors: {
		tenders: string[];
		tsImporter?: { [key: string]: string }[];
	};
	warning: {
		tsImporter?: { site_name: string; message: string }[];
	};
}

export type LoaderType =
	| "tender"
	| "pricingsByTender"
	| "actions"
	| "tsImporter"
	| "workflowView";

const initialState: TenderState = {
	latestUpdatedAt: undefined,
	latestTenderId: undefined,
	isPaginationStopped: false,
	addedTenderCount: 0,
	tenders: [],
	workflowView: {
		[TenderStatus.TODO]: {
			count: 0,
			status: TenderStatus.TODO,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.ONGOING]: {
			count: 0,
			status: TenderStatus.ONGOING,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.PRICING_DONE]: {
			count: 0,
			status: TenderStatus.PRICING_DONE,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.BOOKED]: {
			count: 0,
			status: TenderStatus.BOOKED,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.CLOSED]: {
			count: 0,
			status: TenderStatus.CLOSED,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.LOST]: {
			count: 0,
			status: TenderStatus.LOST,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.TO_BE_CHECKED]: {
			count: 0,
			status: TenderStatus.TO_BE_CHECKED,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.UNDER_OFFER]: {
			count: 0,
			status: TenderStatus.UNDER_OFFER,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
		[TenderStatus.WON]: {
			count: 0,
			status: TenderStatus.WON,
			tenders: [],
			order: Order.desc,
			orderColumn: OrderColumn.createdAt,
		},
	},
	accounts: [],
	tsImporter: undefined,
	loader: {
		tender: false,
		pricingsByTender: {},
		actions: false,
		tsImporter: false,
		workflowView: false,
	},
	errors: {
		tenders: [],
		tsImporter: undefined,
	},
	warning: {
		tsImporter: undefined,
	},
};

export const tenderSlice = createSlice({
	name: "tender",
	initialState,
	reducers: {
		initTenders: (state) => {
			state.tenders = [];
			state.isPaginationStopped = false;
			state.latestUpdatedAt = undefined;
			state.latestTenderId = undefined;
			state.addedTenderCount = 0;
			state.loader.tender = false;
		},
		setTenderLoading: (state) => {
			state.loader.tender = true;
		},
		getTendersSuccess: (state, action) => {
			const { tenders } = action.payload;
			state.loader.tender = false;
			state.tenders = uniqBy([...state.tenders, ...tenders], "id");
			state.isPaginationStopped = !action.payload.hasNextPage;
			return;
		},
		setTenderWorkflow: (state, action) => {
			state.workflowView = { ...state.workflowView, ...action.payload };
			return;
		},
		getTenderWorkflowSuccess: (state, action) => {
			state.workflowView = { ...state.workflowView, ...action.payload };
			state.loader.workflowView = false;
			return;
		},
		getTendersError: (state, action) => {
			state.loader.tender = false;
			state.errors.tenders += action.payload;
		},
		updateNewTendersCount: (state) => {
			state.addedTenderCount = 0;
		},
		updateTenderBlotter: (state, action) => {
			const tenderId = action.payload.id;
			state.tenders = state.tenders.map((tender) => {
				if (tender.id == tenderId)
					return { ...tender, ...action.payload };
				return tender;
			});
		},
		updateTenderStatus: (state, action) => {
			const tenderId = action.payload.tenderId;
			const newStatus = action.payload.status;
			state.tenders = state.tenders.map((tender) => {
				if (tender.id == tenderId) return { ...tender, ...newStatus };
				return tender;
			});
		},
		setTenderPricings: (state, action) => {
			const tenderId = action.payload.tenderId;
			const pricings = action.payload.pricings;
			state.tenders = state.tenders.map((tender) =>
				tender.id === tenderId ? { ...tender, pricings } : tender
			);
		},
		setPricingsByTenderIsLoading: (state, action) => {
			state.loader.pricingsByTender[action.payload.tenderId] =
				action.payload.isLoading;
		},
		setTenders: (state, action) => {
			state.tenders = action.payload;
		},
		setTenderActionLoading: (state, action) => {
			state.loader.actions = action.payload;
		},
		tsImporterSuccess: (state, action) => {
			state.tsImporter = action.payload;
		},
		tsImporterWarning: (state, action) => {
			state.warning.tsImporter = action.payload;
		},
		setTsImporterLoading: (state, action) => {
			state.loader.tsImporter = action.payload;
		},
		setloader: (state, action) => {
			const { key, value } = action.payload;
			state.loader[key as LoaderType] = value;
		},
		setColumnOrder: (state, action) => {
			let {
				status,
				order,
				orderColumn,
			}: {
				status: TenderStatus;
				order?: Order;
				orderColumn?: OrderColumn;
			} = action.payload;
			state.workflowView[status].order = order;
			state.workflowView[status].orderColumn = orderColumn;
			if (order && orderColumn) {
				state.workflowView[status].tenders = _.orderBy(
					state.workflowView[status].tenders,
					[(data) => data[orderColumn as OrderColumn]?.toLowerCase()],
					[order]
				);
			}
		},
		tsImporterError: (state, action) => {
			state.errors.tsImporter = action.payload;
			state.loader.tsImporter = false;
		},
	},
});

export const {
	getTendersSuccess,
	getTendersError,
	initTenders,
	updateTenderBlotter,
	setTenderLoading,
	updateTenderStatus,
	updateNewTendersCount,
	setTenderPricings,
	setPricingsByTenderIsLoading,
	setTenders,
	setTenderActionLoading,
	setTsImporterLoading,
	tsImporterError,
	tsImporterSuccess,
	tsImporterWarning,
	getTenderWorkflowSuccess,
	setloader,
	setTenderWorkflow,
	setColumnOrder,
} = tenderSlice.actions;

export default tenderSlice.reducer;
