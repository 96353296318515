import { createSlice } from "@reduxjs/toolkit";
import { getScrollTopById } from "../../common/utils/scroll";
import { ValidationError } from "../../common/hooks/usePydanticErrorsFormat";
import _ from "lodash";
import { Tender } from "../tender_page/tender.module";

interface ClickabilityParams {
	granularity: string;
	click_count: number;
}

export interface PricingRunSummaryMetric {
	value: number;
	percent?: number;
}

export interface PricingRunSummary {
	P50: PricingRunSummaryMetric;
	Proxy: PricingRunSummaryMetric;
	LF: PricingRunSummaryMetric;
	Cross: PricingRunSummaryMetric;
	XBC: PricingRunSummaryMetric;
	XPC: PricingRunSummaryMetric;
	XDPC: PricingRunSummaryMetric;
	P50_PROV: PricingRunSummaryMetric;
	Shape: PricingRunSummaryMetric;
	BidAskShape: PricingRunSummaryMetric;
	GOO: PricingRunSummaryMetric;
	Alpha: PricingRunSummaryMetric;
	BetaContract: PricingRunSummaryMetric;
	BetaFix: PricingRunSummaryMetric;
	Provision: PricingRunSummaryMetric;
	INDIC: PricingRunSummaryMetric;
	Quantity: PricingRunSummaryMetric;
	CM: PricingRunSummaryMetric;
	AlphaClient: PricingRunSummaryMetric;
	IndicClient: PricingRunSummaryMetric;
	FixingDateTime: PricingRunSummaryMetric;
	BaseloadPrice: PricingRunSummaryMetric;
	BaseloadMid: PricingRunSummaryMetric;
	FixingCommercialMargin: PricingRunSummaryMetric;
	FixPrice: PricingRunSummaryMetric;
	Margin_PROV: PricingRunSummaryMetric;
	GOO_Cross: PricingRunSummaryMetric;
	GOO_Adj: PricingRunSummaryMetric;
	GOO_Price: PricingRunSummaryMetric;
	SetupFee: PricingRunSummaryMetric;
	ImbalanceCostProvision: PricingRunSummaryMetric;
	ProfileCostProvision: PricingRunSummaryMetric;
	DeltaProfileCostProvision: PricingRunSummaryMetric;
	ShapeProvision: PricingRunSummaryMetric;
	ShapeBidAskProvision: PricingRunSummaryMetric;
	FixingGooCommercialMargin: PricingRunSummaryMetric;
	FinalGooPrice: PricingRunSummaryMetric;
	HedgeRatioPercent: PricingRunSummaryMetric;
	TotalPowerMargin: PricingRunSummaryMetric;
	TotalGOOMargin: PricingRunSummaryMetric;
	AlphaIndex: PricingRunSummaryMetric;
}

export interface PricingRun {
	id: number;
	status: PricingRunStatus;
	start_execution: string;
	end_execution: string;
	validity_date: string;
	pricing_run_date: string;
	pricing_id: 1;
	summary?: Partial<PricingRunSummary>;
	computed_status: PricingRunStatus;
	status_comment?: string;
	input_params_s3_filename?: string;
	margin?: number;
	error: string;
	is_pb_error: boolean;
	updated_at: string;
}

export enum PricingRunStatus {
	UNDER_PRICING = "UNDER_PRICING",
	FAILED = "FAILED",
	TIMED_OUT = "TIMED_OUT",
	UNDER_REVIEW = "UNDER_REVIEW",
	INDICATIVE = "INDICATIVE",
	FIRM_REQUESTED = "FIRM_REQUESTED",
	FIRM = "FIRM",
	UNDER_OFFER = "UNDER_OFFER",
	WON = "WON",
	LOST = "LOST",
	CANCELLED = "CANCELLED",
	UNDER_BOOKING = "UNDER_BOOKING",
	BOOKED = "BOOKED",
	BOOKING_FAILED = "BOOKING_FAILED",
}

export enum PricingStatus {
	UNDER_PRICING = "UNDER_PRICING",
	FAILED = "FAILED",
	TIMED_OUT = "TIMED_OUT",
	UNDER_REVIEW = "UNDER_REVIEW",
	INDICATIVE = "INDICATIVE",
	FIRM_REQUESTED = "FIRM_REQUESTED",
	FIRM = "FIRM",
	UNDER_OFFER = "UNDER_OFFER",
	WON = "WON",
	LOST = "LOST",
	CANCELLED = "CANCELLED",
	UNDER_BOOKING = "UNDER_BOOKING",
	BOOKED = "BOOKED",
	BOOKING_FAILED = "BOOKING_FAILED",
	FIXING_REQUESTED = "FIXING_REQUESTED",
	FIXED = "FIXED",
	FIXED_APPROVED = "FIXED_APPROVED",
	BOOKED_MANUALLY = "BOOKED_MANUALLY",
}

export enum TenderStatus {
	TODO = "TODO",
	ONGOING = "ONGOING",
	TO_BE_CHECKED = "TO_BE_CHECKED",
	PRICING_DONE = "PRICING_DONE",
	UNDER_OFFER = "UNDER_OFFER",
	WON = "WON",
	BOOKED = "BOOKED",
	LOST = "LOST",
	CLOSED = "CLOSED",
}

export interface Field {
	required: boolean;
	label: string;
	name?: string;
	prefill?: string;
	display_name?: string;
	title: string;
	type: "number" | "text" | "group" | "date";
	component: string;
	is_array: boolean;
	disabled?: boolean;
	selectable_values?: { value: string; label: string }[];
	must_be_positive: boolean;
}

export interface FieldGroup {
	type?: "group";
	fields: Field[][];
	required?: boolean;
	label?: string;
	name?: string;
	prefill?: string;
	title?: string;
	component?: string;
	display_name?: string;
	is_array?: boolean;
	disabled?: boolean | string;
	selectable_values?: { value: string; label: string }[];
	must_be_positive?: boolean;
}

export interface Action {
	action: string;
	is_unique_by_group: boolean;
	is_unique_by_pricing_type?: boolean;
	by_site_or_portfolios: boolean;
	display_name: string;
	endpoint: string;
	method: "PATCH" | "POST";
	fields: { [key: string]: Field | FieldGroup };
	filters?: string;
	confirmation_modal?: {
		title: string;
		content: string;
		for_status?: string;
	};
	is_batchable?: boolean;
	redirection_url?: string;
}

export interface Party {
	name: string;
	id?: number;
	party_id: number;
	short_name: string;
}

export interface PricingParty {
	id: number;
	party: Party;
	mirror_book?: string;
	percentage: number;
}

export interface Technology {
	name: string;
	id: number;
}

export interface Timeserie {
	best_off_tourbillon_name: string;
	cleaned_realized_tourbillon_name: string;
	outliers_tourbillon_name: string;
	proxy_tourbillon_name: string;
	realized_tourbillon_name: string;
	error: string;
	warning: string;
}

export interface Pricing {
	id: number;
	pricing_type_id: number;
	alpha?: boolean;
	beta?: boolean;
	pricing_type: { name: string; long_name: string; geco_name: string };
	start_date: string;
	end_date: string;
	pricing_date: string;
	margin?: number;
	clickability_params: ClickabilityParams;
	granularity: string;
	tender_id: number;
	result_option: string;
	portfolio_id?: number;
	portfolio?: {
		name: string;
		id: number;
	};
	site_pricings: any[];
	base_capacity_volume_percentage?: number;
	price_max_validity?: "string";
	pricing_runs: PricingRun[];
	status: PricingStatus;
	actions: Action[];
	status_comment: string;
	delivery_period: string;
	geco_contract_id?: number | null;
	installed_capacity: number;
	goo_price?: number;
	party?: Party;
	booking_outputs?: string;
	validity_date: string;
	pricing_group_id: number;
	tourbillon_ref?: string;
	isNewUpdate?: boolean;
	is_real_time?: boolean;
	updated_at: string;
	mirror_book: string;
	party_or_mirror: string;
	isStatusUpdating?: boolean;
	chapter51_type?: string;
	chapter51_number_of_hours?: number;
	technology_id?: number;
	techno?: Technology;
	goo_power_adjustment?: number;
	parties?: any[];
	timeserie?: Timeserie;
	negative_price_value: number;
	balancing_group: string;
	balancing_group_label: string;
	isSelected?: boolean;
	isExpanded?: boolean;
	isLoadingRuns?: boolean;
}

interface PricingListState {
	pricings: Pricing[];
	hasNextPage: boolean;
	latestUpdatedAt: undefined;
	latestPricingId: undefined;
	addedPricingCount: number;
	loader: {
		pricings: boolean;
		export: boolean;
		transition: boolean;
		pricingRuns: Record<number, boolean>;
		parties: boolean;
		tender: boolean;
	};
	errors: {
		pricings?: string;
		export?: any;
		transition?: string | ValidationError;
		parties?: string;
		graphs?: any;
	};
	pricingRuns: Record<number, PricingRun[]>;
	parties: Party[];
	currentTender: Tender | undefined;
	graphTypes: { id: number; ts_name: string }[] | undefined;
}

const initialState: PricingListState = {
	pricings: [],
	hasNextPage: true,
	latestUpdatedAt: undefined,
	latestPricingId: undefined,
	addedPricingCount: 0,
	loader: {
		pricings: false,
		export: false,
		transition: false,
		pricingRuns: {},
		parties: false,
		tender: false,
	},
	errors: {},
	pricingRuns: {},
	parties: [],
	currentTender: undefined,
	graphTypes: undefined,
};

export const pricingListSlice = createSlice({
	name: "pricingList",
	initialState,
	reducers: {
		resetPricings: (state) => {
			state.pricings = [];
			state.pricingRuns = {};
			state.loader.pricingRuns = {};
			state.latestUpdatedAt = undefined;
			state.latestPricingId = undefined;
			state.hasNextPage = true;
			state.addedPricingCount = 0;
		},
		getPricingSuccess: (state, action) => {
			const {
				pricings,
				latestUpdatedAt,
				latestUpdatedAtParam,
				hasNextPage,
			} = action.payload;
			const scrollTop = getScrollTopById("pricing-table-container");
			if (latestUpdatedAtParam) {
				const addedPricings: Pricing[] = [];
				pricings.forEach((pricing: Pricing) => {
					const index = state.pricings.findIndex(
						(p: Pricing) => p.id == pricing.id
					);
					if (index !== -1) {
						state.pricings[index] = pricing;
					} else {
						addedPricings.push(pricing);
					}
				});

				if (addedPricings.length > 0) {
					if (scrollTop != undefined && scrollTop < 100) {
						const sortedState = [
							...addedPricings,
							...state.pricings,
						].sort((a: Pricing, b: Pricing) => b.id - a.id);
						state.pricings = sortedState.slice(0, 20);
						// Trigger a reset of paging count
						state.addedPricingCount = -1;
					} else {
						// Trigger snackbar to reload
						state.addedPricingCount = addedPricings.length;
					}
				}
			} else {
				state.pricings = _.uniqBy(
					_.orderBy(
						[...state.pricings, ...pricings],
						[
							"pricing_group_id",
							"id",
							(pricing) => new Date(pricing.updated_at).getTime(),
						],
						["desc", "desc", "desc"]
					),
					"id"
				);
			}
			state.latestUpdatedAt = latestUpdatedAt;
			state.hasNextPage = hasNextPage;
			state.loader.pricings = false;
		},
		getPricingError: (state, action) => {
			state.errors.pricings = action.payload;
			state.loader.pricings = false;
		},
		getCancelledPricingsError: (state, action) => {
			state.errors.pricings = action.payload;
		},
		transitionError: (state, action) => {
			state.errors.transition = action.payload;
		},
		setLoader: (state, action) => {
			const name: "pricings" | "export" | "transition" | "tender" =
				action.payload.name;
			state.loader[name] = action.payload.value;
		},
		setPricingLoadingState: (state) => {
			state.loader.pricings = true;
		},
		setExportLoadingState: (state, action) => {
			state.loader.export = action.payload;
		},
		setExportLoadingError: (state, action) => {
			state.errors.export = action.payload;
		},
		setPricingRunLoader: (state, action) => {
			const { pricingId, isLoading } = action.payload;
			state.loader.pricingRuns[pricingId] = isLoading;
		},
		pricingRunSuccess: (state, action) => {
			const { pricingId, runs } = action.payload;
			if (!state.pricingRuns[pricingId]) {
				state.pricingRuns[pricingId] = [];
			}
			state.pricingRuns[pricingId] = _.uniqBy(
				[...state.pricingRuns[pricingId], ...runs],
				"id"
			);
		},
		resetPricingRun: (state, action) => {
			const { pricingId } = action.payload;
			state.pricingRuns[pricingId] = [];
		},
		updateNewPricingsCount: (state) => {
			state.addedPricingCount = 0;
		},
		getPartiesSuccess: (state, action) => {
			state.parties = action.payload;
			state.loader.parties = false;
		},
		updatePartiesLoader: (state, action) => {
			state.loader.parties = action.payload;
		},
		getPartiesError: (state, action) => {
			state.errors.parties = action.payload;
			state.loader.parties = false;
		},
		setPricingTender: (state, action) => {
			state.currentTender = action.payload;
		},
		updatePricingsTender: (state, action) => {
			if (
				state.currentTender?.id == action.payload.id ||
				state.currentTender == undefined
			)
				state.currentTender = action.payload;
		},
		setPricingsStatusLoading: (state, action) => {
			state.pricings = state.pricings.map((pricing) => {
				if (action.payload.ids.includes(pricing.id)) {
					return {
						...pricing,
						isStatusUpdating: action.payload.status,
					};
				}
				return pricing;
			});
		},
		setPricings: (state, action) => {
			state.pricings = action.payload;
		},

		getGraphTypesSuccess: (state, action) => {
			state.graphTypes = action.payload;
		},
		getGraphError: (state, action) => {
			state.errors.graphs = action.payload;
		},

		initGraphTypes: (state) => {
			state.graphTypes = undefined;
		},
	},
});

export const {
	getPricingSuccess,
	getPricingError,
	setPricingLoadingState,
	resetPricings,
	setExportLoadingState,
	setExportLoadingError,
	transitionError,
	setLoader,
	setPricingRunLoader,
	pricingRunSuccess,
	resetPricingRun,
	updateNewPricingsCount,
	getPartiesSuccess,
	getPartiesError,
	updatePartiesLoader,
	updatePricingsTender,
	setPricingTender,
	getCancelledPricingsError,
	setPricingsStatusLoading,
	setPricings,
	getGraphTypesSuccess,
	getGraphError,
	initGraphTypes,
} = pricingListSlice.actions;

export default pricingListSlice.reducer;
