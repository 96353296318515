import { apiGet } from "../../common/utils/request.utils";
import { RootState } from "../../core/rootReducers";
import { AppDispatch } from "../../store";
import { generateOdataQuery } from "../../common/utils/odata";
import { GroupedResponseCount } from "./grouping.module";
import { selectPricingSelectedGroupingFields } from "./grouping.selector";
import * as groupingActions from "./grouping.slice";
import * as _ from "lodash";
import { selectPricingsOdataQuery } from "../filters/filters.selector";
import { setPricingRuns, setPricingRunsLoading } from "./grouping.slice";
import { GroupingField } from "../metadata/metadata.module";

function makeGroupCountAsNewUpdate(groupCount: GroupedResponseCount) {
	return groupCount.map((group) => {
		const newGroup = { ...group, isNewUpdate: true };
		if (group.children?.length) {
			newGroup.children = makeGroupCountAsNewUpdate(group.children);
		}
		return newGroup;
	});
}

export function reloadPricingsGroupCount(
	tenderId: number,
	odataQuery?: string
) {
	return async function (dispatch: AppDispatch, getState: () => RootState) {
		dispatch(groupingActions.startLoadingGroupCount({ clear: false }));
		try {
			const groups = selectPricingSelectedGroupingFields(getState());
			let endpoint = `/pricing/grouped?tender_id=${tenderId}&groups=${_.map(
				groups,
				"field_key"
			)}`;
			if (odataQuery) {
				endpoint += `&filters=${odataQuery}`;
			}
			const response = await apiGet(endpoint);
			const { data } = response.data;
			dispatch(groupingActions.groupCountOverwrite(data));
		} catch (err: any) {
			if (err.response) {
				dispatch(groupingActions.groupCountFailure(err.response));
			} else if (err.request) {
				dispatch(
					groupingActions.groupCountFailure("Unable to reach API")
				);
			} else {
				dispatch(
					groupingActions.groupCountFailure("Internal Error: " + err)
				);
			}
		}
	};
}

function getGroupCountError(err: any) {
	if (err.response) {
		return err.response;
	} else if (err.request) {
		return "Unable to reach API";
	} else {
		return "Internal Error: " + err;
	}
}

// Special version that loads concurrently the root group All and it's pricings
export function initialGroupLoading(
	tenderId: number,
	odataQuery: string | null | undefined
) {
	return async function (dispatch: AppDispatch, getState: () => RootState) {
		dispatch(groupingActions.startLoadingGroupCount({ clear: true }));
		try {
			let groupEndpoint = `/pricing/grouped?tender_id=${tenderId}&groups=`;
			if (odataQuery) {
				groupEndpoint += `&filters=${odataQuery}`;
			}

			const rootFiltersQuery = selectPricingsOdataQuery(getState());
			const specificQuery = generateOdataQuery([], rootFiltersQuery);
			const [groupResponse, pricingsResponse] = await Promise.all([
				apiGet(groupEndpoint),
				apiGet(
					`/pricing?page=${1}&per_page=${100}&tender_id=${tenderId}&filters="${specificQuery}"`
				),
			]);

			const group = groupResponse.data.data;
			dispatch(groupingActions.groupCountSuccess(group));
			dispatch(
				groupingActions.setPricings({
					groupPath: ["AllAll"],
					pricings: pricingsResponse.data.data,
					thereAreMorePricingsToLoad:
						pricingsResponse.data.has_next_page,
				})
			);
		} catch (err) {
			dispatch(
				groupingActions.groupCountFailure(getGroupCountError(err))
			);
		}
	};
}

export function loadPricingsGroupCount(
	tenderId: number,
	odataQuery: string | null | undefined,
	isNewUpdate: boolean | null | undefined,
	groups: GroupingField[]
) {
	return async function (dispatch: AppDispatch) {
		if (!isNewUpdate) {
			dispatch(groupingActions.startLoadingGroupCount({ clear: false }));
		}
		try {
			let endpoint = `/pricing/grouped?tender_id=${tenderId}&groups=${_.map(
				groups,
				"field_key"
			)}`;
			if (odataQuery) {
				endpoint += `&filters=${odataQuery}`;
			}
			const response = await apiGet(endpoint);
			const { data } = response.data;
			if (isNewUpdate) {
				dispatch(
					groupingActions.groupCountSuccess(
						makeGroupCountAsNewUpdate(data)
					)
				);
			} else {
				dispatch(groupingActions.groupCountSuccess(data));
			}
		} catch (err: any) {
			if (!isNewUpdate) {
				dispatch(
					groupingActions.groupCountFailure(getGroupCountError(err))
				);
			}
		}
	};
}

export function loadGroupPricings(
	tenderId: number | undefined,
	groupPath: string[]
) {
	return async function (dispatch: AppDispatch, getState: () => RootState) {
		const group = groupingActions.findGroup(getState().grouping, groupPath);
		if (tenderId && group) {
			dispatch(groupingActions.startPricingsLoading({ groupPath }));
			const rootFiltersQuery = selectPricingsOdataQuery(getState());
			const specificQuery = generateOdataQuery(
				group.filters,
				rootFiltersQuery
			);
			const response = await apiGet(
				`/pricing?page=${1}&per_page=${100}&tender_id=${tenderId}&filters="${specificQuery}"`
			);
			dispatch(
				groupingActions.setPricings({
					groupPath,
					pricings: response.data.data,
					thereAreMorePricingsToLoad: response.data.has_next_page,
				})
			);
		}
	};
}

export function loadGroupPricingsNextPage(
	tenderId: number | undefined,
	groupPath: string[]
) {
	return async function (dispatch: AppDispatch, getState: () => RootState) {
		const group = groupingActions.findGroup(getState().grouping, groupPath);
		if (
			tenderId &&
			group &&
			!group.pricingsAreLoading &&
			group.thereAreMorePricingsToLoad
		) {
			dispatch(groupingActions.startPricingsLoading({ groupPath }));
			const rootFiltersQuery = selectPricingsOdataQuery(getState());
			const specificQuery = generateOdataQuery(
				group.filters,
				rootFiltersQuery
			);
			const nextPage = (group.page || 0) + 1;
			const response = await apiGet(
				`/pricing?page=${nextPage}&per_page=${100}&tender_id=${tenderId}&filters="${specificQuery}"`
			);
			dispatch(
				groupingActions.addPricings({
					groupPath,
					pricings: response.data.data,
					thereAreMorePricingsToLoad: response.data.has_next_page,
					page: response.data.page,
				})
			);
		}
	};
}

export function loadPricingRuns(pricingId: number, page: number) {
	return async function (dispatch: AppDispatch) {
		dispatch(setPricingRunsLoading({ id: pricingId, loading: true }));
		try {
			const response = await apiGet(
				`pricing-run?pricing_id=${pricingId}&page=${page}&per_page=5`
			);
			dispatch(
				setPricingRuns({ id: pricingId, runs: response.data.data })
			);
		} finally {
			dispatch(setPricingRunsLoading({ id: pricingId, loading: false }));
		}
	};
}
