import { ReactElement, useCallback, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { setNotificationItem } from "../features/notifications/notificationsSlice";
import { WsEventTypes } from "../common/constants/wsEvents";

import {
	subscribe as subscribeToWs,
	send as sendToWs,
} from "./webSocketClient";
import wsPublisher$ from "./wsPublisher";
import { updateWsConnected } from "../features/authentication/authSlice";
import { useAppDispatch } from "../common/hooks/default";

export interface WsMessage<T> {
	type: WsEventTypes;
	data: T;
	at: string;
	url?: string;
	tender_id?: number;
}

const SocketProvider = (): ReactElement => {
	const { authState } = useOktaAuth();
	const dispatch = useAppDispatch();

	// forward every message from websocket worker to publisher.
	// we then subscribe here for global state changes (so that publisher is single entry point)
	// would be better to move these to their own components
	useEffect(() => {
		return subscribeToWs((message: WsMessage<any>) =>
			wsPublisher$.publish(message)
		);
	}, []);

	useEffect(() => {
		sendToWs({
			type: "SET_WS_URL",
			url: `${process.env.REACT_APP_WS_APIGATEWAY_URL}?Authorization=${authState?.accessToken?.accessToken}`,
		});
	}, [authState]);

	const onNotificationMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			messages.forEach((message) => {
				dispatch(setNotificationItem(message.data));
			});
		},
		[dispatch]
	);

	const onWebsocketConnectedMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			messages.forEach((message) => {
				dispatch(updateWsConnected(message.data));
			});
		},
		[dispatch]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.NOTIFICATION_CREATED],
			onNotificationMessages
		);
	}, [onNotificationMessages]);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.WEBSOCKET_CONNECTED],
			onWebsocketConnectedMessages
		);
	}, [onWebsocketConnectedMessages]);

	useEffect(() => {
		const interval = setInterval(() => {
			sendToWs({ isAlive: true, action: "keep_alive" });
		}, 10000);
		return () => clearInterval(interval);
	});

	return <></>;
};
export default SocketProvider;
