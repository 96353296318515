export function mapToQueryString(it: Record<string, any>): string {
	const copy = { ...it };
	if ("filters" in copy && copy.filters) {
		const filterStr = copy.filters as string;
		if (!filterStr.startsWith('"') || !filterStr.endsWith('"')) {
			copy.filters = '"' + filterStr + '"';
		}
	} else if ("filters" in copy && !copy.filters) {
		delete copy.filters;
	}
	return new URLSearchParams(copy).toString();
}
