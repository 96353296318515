export function and(of: string[]) {
	const parts = of.filter((part) => !!part?.length);
	if (parts.length > 1) {
		return parts.map((part) => `(${part})`).join(" and ");
	} else if (parts.length === 1) {
		return parts[0];
	}
	return parts[0];
}

export function or(of: string[]) {
	const parts = of.filter((part) => !!part?.length);
	if (parts.length > 1) {
		return parts.map((part) => `(${part})`).join(" or ");
	} else if (parts.length === 1) {
		return parts[0];
	}
	return "";
}

export function toString(it: string | number): string {
	if (typeof it === "string") {
		return `'${it}'`;
	} else if (typeof it === "number") {
		return it.toString();
	}
	throw new Error(`Unsupported type for odata querystring: ${typeof it}`);
}

export function fieldIn(field: string, options: any[]) {
	const endToken = options.length === 1 ? ",)" : ")";
	return field + " in (" + options.map(toString).join(",") + endToken;
}
export function prepareOdataValue(value: string | number | boolean) {
	if (typeof value === "string") {
		return value.replaceAll("'", "''");
	}
	return value;
}

export function computedFiltersToOdata(
	filters: { value: string; field_path: string }[]
) {
	return and(
		filters.map((filter) => {
			if (filter.value === null) {
				return `${filter.field_path} eq null`;
			}
			return `${filter.field_path} in ('${encodeURIComponent(
				prepareOdataValue(filter.value)
			)}',)`;
		})
	);
}

export function generateOdataQuery(
	filters: { value: string; field_path: string }[],
	odataQuery?: string | undefined
) {
	const groupFilters = and(
		filters.map((filter) => {
			if (filter.value === null) {
				return `${filter.field_path} eq null`;
			}
			return `${filter.field_path} in ('${encodeURIComponent(
				prepareOdataValue(filter.value)
			)}',)`;
		})
	);
	if (odataQuery && odataQuery.replace(/"/g, "")) {
		return and([groupFilters, odataQuery.replace(/"/g, "")]);
	}
	return groupFilters || "";
}
